import React, { useState } from 'react';

const PurchasedStockEntry = () => {
  const [id, setId] = useState(1); // Add id state
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [selectedProduct, setSelectedProduct] = useState('');
  const [products] = useState([
    { id: 1, name: 'Product A' },
    { id: 2, name: 'Product B' },
    { id: 3, name: 'Product C' },
  ]);
  const [vendor, setVendor] = useState('');
  const [vendors] = useState([
    { id: 1, name: 'Vendor X' },
    { id: 2, name: 'Vendor Y' },
    { id: 3, name: 'Vendor Z' },
  ]);
  const [quantity, setQuantity] = useState('');
  const [purchaseRate, setPurchaseRate] = useState('');
  const [mrp, setMRP] = useState('');
  const [totalPayable, setTotalPayable] = useState('');
  const [totalPaid, setTotalPaid] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('Cash');
  const [totalDue, setTotalDue] = useState('');
  const [documents, setDocuments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const handleAddEntry = () => {
    const calculatedTotalPayable = quantity * purchaseRate;
    const calculatedTotalDue = calculatedTotalPayable - totalPaid;

    const newEntry = {
      id,
      date,
      product: selectedProduct,
      vendor,
      quantity,
      purchaseRate,
      mrp,
      totalPayable: calculatedTotalPayable,
      totalPaid,
      paymentMethod,
      totalDue: calculatedTotalDue,
      documents: Array.from(documents), // Convert FileList to an array
    };

    if (editingIndex !== null) {
      // Replace the entry at the editing index
      const updatedTableData = [...tableData];
      updatedTableData[editingIndex] = newEntry;
      setTableData(updatedTableData);
      setEditingIndex(null);
    } else {
      // Add a new entry to the table
      setTableData((prevData) => [...prevData, newEntry]);
      setId((prevId) => prevId + 1); // Increment id for the next entry
    }

    // Clear input values
    setDate(new Date().toLocaleDateString());
    setSelectedProduct('');
    setVendor('');
    setQuantity('');
    setPurchaseRate('');
    setMRP('');
    setTotalPayable('');
    setTotalPaid('');
    setPaymentMethod('Cash');
    setTotalDue('');
    setDocuments([]);
  };

  const handleEditEntry = (index) => {
    const entryToEdit = tableData[index];
    setId(entryToEdit.id);
    setDate(entryToEdit.date);
    setSelectedProduct(entryToEdit.product);
    setVendor(entryToEdit.vendor);
    setQuantity(entryToEdit.quantity);
    setPurchaseRate(entryToEdit.purchaseRate);
    setMRP(entryToEdit.mrp);
    setTotalPayable(entryToEdit.totalPayable);
    setTotalPaid(entryToEdit.totalPaid);
    setPaymentMethod(entryToEdit.paymentMethod);
    setTotalDue(entryToEdit.totalDue);
    setDocuments(entryToEdit.documents);

    setEditingIndex(index);
  };

  const handleSave = () => {
    console.log('Complete Object:', {
      entries: tableData,
      // Add other relevant data as needed
    });
  };

  return (
    <div className="container mx-auto mt-8 p-4">
      <h1 className="text-3xl font-bold mb-4">Purchased Stock Entry</h1>

      <div>
        <label className="block text-sm font-medium text-gray-700">ID:</label>
        <input
          type="text"
          value={id}
          readOnly
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Date:</label>
        <input
          type="text"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Product:</label>
        <select
          value={selectedProduct}
          onChange={(e) => setSelectedProduct(e.target.value)}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        >
          <option value="">Select Product</option>
          {products.map((product) => (
            <option key={product.id} value={product.name}>
              {product.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Vendor:</label>
        <select
          value={vendor}
          onChange={(e) => setVendor(e.target.value)}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        >
          <option value="">Select Vendor</option>
          {vendors.map((vendor) => (
            <option key={vendor.id} value={vendor.name}>
              {vendor.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Quantity:</label>
        <input
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Purchase Rate:</label>
        <input
          type="number"
          value={purchaseRate}
          onChange={(e) => setPurchaseRate(e.target.value)}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">MRP:</label>
        <input
          type="number"
          value={mrp}
          onChange={(e) => setMRP(e.target.value)}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Total Payable:</label>
        <input
          type="number"
          value={totalPayable}
          readOnly
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Total Paid:</label>
        <input
          type="number"
          value={totalPaid}
          onChange={(e) => setTotalPaid(e.target.value)}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Payment Method:</label>
        <select
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        >
          <option value="Cash">Cash</option>
          <option value="Card">Card</option>
          <option value="UPI">UPI</option>
          <option value="Credit Card">Credit Card</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Total Due:</label>
        <input
          type="number"
          value={totalDue}
          readOnly
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Documents:</label>
        <input
          type="file"
          multiple
          onChange={(e) => setDocuments(e.target.files)}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        />
      </div>

      <button
        onClick={handleAddEntry}
        className="mt-4 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
      >
        Add Entry
      </button>

      <table className="mt-4 w-full border border-collapse">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Product</th>
            <th>Vendor</th>
            <th>Quantity</th>
            <th>Purchase Rate</th>
            <th>MRP</th>
            <th>Total Payable</th>
            <th>Total Paid</th>
            <th>Payment Method</th>
            <th>Total Due</th>
            <th>Documents</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((entry, index) => (
            <tr key={index}>
              <td>{entry.id}</td>
              <td>{entry.date}</td>
              <td>{entry.product}</td>
              <td>{entry.vendor}</td>
              <td>{entry.quantity}</td>
              <td>{entry.purchaseRate}</td>
              <td>{entry.mrp}</td>
              <td>{entry.totalPayable}</td>
              <td>{entry.totalPaid}</td>
              <td>{entry.paymentMethod}</td>
              <td>{entry.totalDue}</td>
              <td>
                {entry.documents.length > 0 && (
                  <ul>
                    {entry.documents.map((document, documentIndex) => (
                      <li key={documentIndex}>{document.name}</li>
                    ))}
                  </ul>
                )}
              </td>
              <td>
                <button onClick={() => handleEditEntry(index)}>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button
        onClick={handleSave}
        className="mt-4 p-2 bg-green-500 text-white rounded-md hover:bg-green-600"
      >
        Save
      </button>
    </div>
  );
};

export default PurchasedStockEntry;
