// src/ManageNoticePage.js
import React, { useState } from 'react';

const ManageNoticePage = () => {
  const [notices, setNotices] = useState([
    { id: 1, title: 'Notice 1', content: 'This is the content of Notice 1' },
    { id: 2, title: 'Notice 2', content: 'This is the content of Notice 2' },
    // ... add more notices as needed
  ]);

  const [newNotice, setNewNotice] = useState({ title: '', content: '' });
  const [editedNotice, setEditedNotice] = useState(null);

  const handleAddNotice = () => {
    setNotices((prevNotices) => [
      ...prevNotices,
      { id: Date.now(), title: newNotice.title, content: newNotice.content },
    ]);
    setNewNotice({ title: '', content: '' });
  };

  const handleEditNotice = () => {
    setNotices((prevNotices) =>
      prevNotices.map((notice) => (notice.id === editedNotice.id ? editedNotice : notice))
    );
    setEditedNotice(null);
  };

  const handleDeleteNotice = (id) => {
    setNotices((prevNotices) => prevNotices.filter((notice) => notice.id !== id));
  };

  return (
    <div className="container mx-auto my-8">
      <h1 className="text-3xl font-semibold mb-4">Manage Notices</h1>

      {/* Add Notice Section */}
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-1">Title:</label>
        <input
          type="text"
          className="w-full p-2 border rounded"
          placeholder="Enter title"
          value={newNotice.title}
          onChange={(e) => setNewNotice({ ...newNotice, title: e.target.value })}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-1">Content:</label>
        <textarea
          className="w-full p-2 border rounded"
          rows="4"
          placeholder="Enter content"
          value={newNotice.content}
          onChange={(e) => setNewNotice({ ...newNotice, content: e.target.value })}
        ></textarea>
      </div>
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        onClick={handleAddNotice}
      >
        Add Notice
      </button>

      {/* Notices List */}
      {notices.map((notice) => (
        <div key={notice.id} className="border p-4 mt-4 rounded">
          {editedNotice?.id === notice.id ? (
            <>
              <h2 className="text-xl font-semibold mb-2">Editing: {notice.title}</h2>
              <div className="mb-4">
                <label className="block text-sm font-semibold mb-1">Title:</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={editedNotice.title}
                  onChange={(e) => setEditedNotice({ ...editedNotice, title: e.target.value })}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-semibold mb-1">Content:</label>
                <textarea
                  className="w-full p-2 border rounded"
                  rows="4"
                  value={editedNotice.content}
                  onChange={(e) => setEditedNotice({ ...editedNotice, content: e.target.value })}
                ></textarea>
              </div>
              <button
                className="bg-green-500 text-white px-2 py-1 mr-2 rounded hover:bg-green-700"
                onClick={handleEditNotice}
              >
                Save
              </button>
            </>
          ) : (
            <>
              <h2 className="text-xl font-semibold mb-2">{notice.title}</h2>
              <p className="text-gray-600">{notice.content}</p>
              <div className="mt-2">
                <button
                  className="bg-blue-500 text-white px-2 py-1 mr-2 rounded hover:bg-blue-700"
                  onClick={() => setEditedNotice(notice)}
                >
                  Edit
                </button>
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700"
                  onClick={() => handleDeleteNotice(notice.id)}
                >
                  Delete
                </button>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ManageNoticePage;
