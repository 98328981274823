// src/TeamAttendanceTable.js
import React, { useRef } from 'react';

const TeamAttendanceTable = () => {
  // Dummy data with the new date format
  const tableData = [
    {
      member: 'John Doe',
      photo: 'https://placekitten.com/50/50', // Replace with actual member photo URL
      dates: [
        { date: '01/01/2024', dayOfWeek: 'Sun', attendanceLabel: 'Present', status: 'Pending' },
        { date: '02/01/2024', dayOfWeek: 'Mon', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '03/01/2024', dayOfWeek: 'Tue', attendanceLabel: 'Present', status: 'Pending' },
        { date: '04/01/2024', dayOfWeek: 'Wed', attendanceLabel: 'Present', status: 'Pending' },
        { date: '05/01/2024', dayOfWeek: 'Thu', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '06/01/2024', dayOfWeek: 'Fri', attendanceLabel: 'Present', status: 'Pending' },
        { date: '07/01/2024', dayOfWeek: 'Sat', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '08/01/2024', dayOfWeek: 'Sun', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '09/01/2024', dayOfWeek: 'Mon', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '10/01/2024', dayOfWeek: 'Tue', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '11/01/2024', dayOfWeek: 'Wed', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '12/01/2024', dayOfWeek: 'Thu', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '13/01/2024', dayOfWeek: 'Fri', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '14/01/2024', dayOfWeek: 'Sat', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '15/01/2024', dayOfWeek: 'Sun', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '16/01/2024', dayOfWeek: 'Mon', attendanceLabel: 'Absent', status: 'Pending' },
      ],
    },
    {
      member: 'Jane Smith',
      photo: 'https://placekitten.com/50/51', // Replace with actual member photo URL
      dates: [
        { date: '01/01/2024', dayOfWeek: 'Sun', attendanceLabel: 'Present', status: 'Pending' },
        { date: '02/01/2024', dayOfWeek: 'Mon', attendanceLabel: 'Present', status: 'Pending' },
        { date: '03/01/2024', dayOfWeek: 'Tue', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '04/01/2024', dayOfWeek: 'Wed', attendanceLabel: 'Present', status: 'Pending' },
        { date: '05/01/2024', dayOfWeek: 'Thu', attendanceLabel: 'Present', status: 'Pending' },
        { date: '06/01/2024', dayOfWeek: 'Fri', attendanceLabel: 'Present', status: 'Pending' },
        { date: '07/01/2024', dayOfWeek: 'Sat', attendanceLabel: 'Absent', status: 'Accepted' },
      ],
    },
    {
      member: 'Jane Smith',
      photo: 'https://placekitten.com/50/51', // Replace with actual member photo URL
      dates: [
        { date: '01/01/2024', dayOfWeek: 'Sun', attendanceLabel: 'Present', status: 'Pending' },
        { date: '02/01/2024', dayOfWeek: 'Mon', attendanceLabel: 'Present', status: 'Pending' },
        { date: '03/01/2024', dayOfWeek: 'Tue', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '04/01/2024', dayOfWeek: 'Wed', attendanceLabel: 'Present', status: 'Pending' },
        { date: '05/01/2024', dayOfWeek: 'Thu', attendanceLabel: 'Present', status: 'Pending' },
        { date: '06/01/2024', dayOfWeek: 'Fri', attendanceLabel: 'Present', status: 'Pending' },
        { date: '07/01/2024', dayOfWeek: 'Sat', attendanceLabel: 'Absent', status: 'Accepted' },
      ],
    },
    {
      member: 'Jane Smith',
      photo: 'https://placekitten.com/50/51', // Replace with actual member photo URL
      dates: [
        { date: '01/01/2024', dayOfWeek: 'Sun', attendanceLabel: 'Present', status: 'Pending' },
        { date: '02/01/2024', dayOfWeek: 'Mon', attendanceLabel: 'Present', status: 'Pending' },
        { date: '03/01/2024', dayOfWeek: 'Tue', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '04/01/2024', dayOfWeek: 'Wed', attendanceLabel: 'Present', status: 'Pending' },
        { date: '05/01/2024', dayOfWeek: 'Thu', attendanceLabel: 'Present', status: 'Pending' },
        { date: '06/01/2024', dayOfWeek: 'Fri', attendanceLabel: 'Present', status: 'Pending' },
        { date: '07/01/2024', dayOfWeek: 'Sat', attendanceLabel: 'Absent', status: 'Accepted' },
      ],
    },
    {
      member: 'Jane Smith',
      photo: 'https://placekitten.com/50/51', // Replace with actual member photo URL
      dates: [
        { date: '01/01/2024', dayOfWeek: 'Sun', attendanceLabel: 'Present', status: 'Pending' },
        { date: '02/01/2024', dayOfWeek: 'Mon', attendanceLabel: 'Present', status: 'Pending' },
        { date: '03/01/2024', dayOfWeek: 'Tue', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '04/01/2024', dayOfWeek: 'Wed', attendanceLabel: 'Present', status: 'Pending' },
        { date: '05/01/2024', dayOfWeek: 'Thu', attendanceLabel: 'Present', status: 'Pending' },
        { date: '06/01/2024', dayOfWeek: 'Fri', attendanceLabel: 'Present', status: 'Pending' },
        { date: '07/01/2024', dayOfWeek: 'Sat', attendanceLabel: 'Absent', status: 'Accepted' },
      ],
    },
    {
      member: 'Jane Smith',
      photo: 'https://placekitten.com/50/51', // Replace with actual member photo URL
      dates: [
        { date: '01/01/2024', dayOfWeek: 'Sun', attendanceLabel: 'Present', status: 'Pending' },
        { date: '02/01/2024', dayOfWeek: 'Mon', attendanceLabel: 'Present', status: 'Pending' },
        { date: '03/01/2024', dayOfWeek: 'Tue', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '04/01/2024', dayOfWeek: 'Wed', attendanceLabel: 'Present', status: 'Pending' },
        { date: '05/01/2024', dayOfWeek: 'Thu', attendanceLabel: 'Present', status: 'Pending' },
        { date: '06/01/2024', dayOfWeek: 'Fri', attendanceLabel: 'Present', status: 'Pending' },
        { date: '07/01/2024', dayOfWeek: 'Sat', attendanceLabel: 'Absent', status: 'Accepted' },
      ],
    },
    {
      member: 'Jane Smith',
      photo: 'https://placekitten.com/50/51', // Replace with actual member photo URL
      dates: [
        { date: '01/01/2024', dayOfWeek: 'Sun', attendanceLabel: 'Present', status: 'Pending' },
        { date: '02/01/2024', dayOfWeek: 'Mon', attendanceLabel: 'Present', status: 'Pending' },
        { date: '03/01/2024', dayOfWeek: 'Tue', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '04/01/2024', dayOfWeek: 'Wed', attendanceLabel: 'Present', status: 'Pending' },
        { date: '05/01/2024', dayOfWeek: 'Thu', attendanceLabel: 'Present', status: 'Pending' },
        { date: '06/01/2024', dayOfWeek: 'Fri', attendanceLabel: 'Present', status: 'Pending' },
        { date: '07/01/2024', dayOfWeek: 'Sat', attendanceLabel: 'Absent', status: 'Accepted' },
      ],
    },
    {
      member: 'Jane Smith',
      photo: 'https://placekitten.com/50/51', // Replace with actual member photo URL
      dates: [
        { date: '01/01/2024', dayOfWeek: 'Sun', attendanceLabel: 'Present', status: 'Pending' },
        { date: '02/01/2024', dayOfWeek: 'Mon', attendanceLabel: 'Present', status: 'Pending' },
        { date: '03/01/2024', dayOfWeek: 'Tue', attendanceLabel: 'Absent', status: 'Accepted' },
        { date: '04/01/2024', dayOfWeek: 'Wed', attendanceLabel: 'Present', status: 'Pending' },
        { date: '05/01/2024', dayOfWeek: 'Thu', attendanceLabel: 'Present', status: 'Pending' },
        { date: '06/01/2024', dayOfWeek: 'Fri', attendanceLabel: 'Present', status: 'Pending' },
        { date: '07/01/2024', dayOfWeek: 'Sat', attendanceLabel: 'Absent', status: 'Accepted' },
      ],
    },
    // ... add more members
  ];

  const tbodyRef = useRef(null);

  const handleRowScroll = (rowIndex, direction) => {
    if (tbodyRef.current) {
      const row = tbodyRef.current.children[rowIndex];

      if (direction === 'left') {
        row.scrollLeft -= 200; // Adjust the scroll distance as needed
      } else if (direction === 'right') {
        row.scrollLeft += 200; // Adjust the scroll distance as needed
      }
    }
  };

  return (
    <div className="overflow-x-auto ">
      <div className="max-w-screen-auto mx-auto relative">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-3 px-6 text-left">Photo</th>
              {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                <th key={day} className="py-3 px-6 text-left">
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody ref={tbodyRef} className="scrolling-tbody ">
            {tableData.map((memberData, rowIndex) => (
              <tr key={memberData.member} className="whitespace-nowrap">
                <td className="py-3 px-5 relative">
                  <img src={memberData.photo} alt={memberData.member} className="w-14 h-14 rounded-full" />
                  {/* <div className="flex mt-2 absolute top-0 right-0">
                    <button className="bg-blue-500 text-white px-2 py-1 mr-2 rounded" onClick={() => handleRowScroll(rowIndex, 'left')}>←</button>
                    <button className="bg-blue-500 text-white px-2 py-1 rounded" onClick={() => handleRowScroll(rowIndex, 'right')}>→</button>
                  </div> */}
                </td>
                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => {
                  const dateData = memberData.dates.find((date) => date.dayOfWeek === day);
                  return (
                    <td key={`${memberData.member}-${day}`} className="py-4 px-6">
                      <div className="bg-gray-100 p-2 rounded">
                        <p className="text-sm font-semibold">{dateData ? dateData.attendanceLabel : "-"}</p>
                        <p className="text-xs text-gray-500">{dateData ? dateData.status : "-"}</p>
                        <p className="text-xs text-gray-500">{dateData ? dateData.date : "-"}</p>
                        {dateData?.status === 'Pending' && (
                          <div className="flex mt-2">
                            <button className="bg-green-500 text-white px-2 py-1 mr-2 rounded" onClick={() => console.log('Accept clicked')}>Accept</button>
                            <button className="bg-red-500 text-white px-2 py-1 rounded" onClick={() => console.log('Cancel clicked')}>Cancel</button>
                          </div>
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamAttendanceTable;