import React, { useState } from 'react';

const attendanceData = [
  {
    id: 1,
    name: 'John Doe',
    rollNo: '101',
    className: 'Class 1',
    photoUrl: 'https://picsum.photos/50/50?random=1',
    attendance: [
      { day: 'Mon', date: '2022-01-10', status: 'P' },
      { day: 'Tue', date: '2022-01-11', status: 'A' },
      { day: 'Wed', date: '2022-01-12', status: 'P' },
      { day: 'Thu', date: '2022-01-13', status: 'L' },
      { day: 'Fri', date: '2022-01-14', status: 'L' },
      { day: 'Sat', date: '2022-01-15', status: 'L' },
      { day: 'Sun', date: '2022-01-16', status: 'A' },
      { day: 'Mon', date: '2022-01-17', status: 'P' },
      { day: 'Tue', date: '2022-01-18', status: 'A' },
      { day: 'Wed', date: '2022-01-19', status: 'P' },
      { day: 'Thu', date: '2022-01-20', status: 'L' },
      { day: 'Fri', date: '2022-01-21', status: 'A' },
      { day: 'Sat', date: '2022-01-22', status: 'P' },
      { day: 'Sun', date: '2022-01-23', status: 'A' },
    ],
  },
  {
    id: 2,
    name: 'Mon Doe',
    rollNo: '102',
    className: 'Class 1',
    photoUrl: 'https://picsum.photos/50/50?random=2',
    attendance: [
      { day: 'Mon', date: '2022-01-10', status: 'P' },
      { day: 'Tue', date: '2022-01-11', status: 'A' },
      { day: 'Wed', date: '2022-01-12', status: 'P' },
      { day: 'Thu', date: '2022-01-13', status: 'L' },
      { day: 'Fri', date: '2022-01-14', status: 'A' },
      { day: 'Sat', date: '2022-01-15', status: 'P' },
      { day: 'Sun', date: '2022-01-16', status: 'A' },
      { day: 'Mon', date: '2022-01-17', status: 'P' },
      { day: 'Tue', date: '2022-01-18', status: 'A' },
      { day: 'Wed', date: '2022-01-19', status: 'P' },
      { day: 'Thu', date: '2022-01-20', status: 'L' },
      { day: 'Fri', date: '2022-01-21', status: 'A' },
      { day: 'Sat', date: '2022-01-22', status: 'P' },
      { day: 'Sun', date: '2022-01-23', status: 'A' },
    ],
  },
  // Add more attendance data as needed
];


const ViewAttendance = () => {
  const [selectedClass, setSelectedClass] = useState('');
  const [startDateIndexes, setStartDateIndexes] = useState({});
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    // Reset start date indexes when class changes
    setStartDateIndexes({});
  };

  const handlePrevWeek = (studentId) => {
    setStartDateIndexes((prevIndexes) => ({
      ...prevIndexes,
      [studentId]: Math.max(0, (prevIndexes[studentId] || 0) - 7),
    }));
  };

  const handleNextWeek = (studentId, maxIndex) => {
    setStartDateIndexes((prevIndexes) => ({
      ...prevIndexes,
      [studentId]: Math.min((prevIndexes[studentId] || 0) + 7, maxIndex - 7),
    }));
  };

  const getMaxAttendanceLength = (data, className) => {
    const classData = data.filter((student) => student.className === className);
    if (classData.length === 0) return 0;

    return Math.max(...classData.map((student) => student.attendance.length));
  };

  const getAttendanceForDay = (student, dayIndex) => {
    const attendanceDayIndex = startDateIndexes[student.id] + dayIndex;
    const attendanceDay = student.attendance[attendanceDayIndex] || null;

    return attendanceDay ? `${attendanceDay.status} (${attendanceDay.date})` : '-';
  };

  const getBackgroundColor = (status) => {
    
    switch (true) {
      case status.startsWith('P'):
        return 'bg-green-500';
      case status.startsWith('A'):
        return 'bg-red-500';
      case status.startsWith('L'):
        return 'bg-blue-500';
      default:
        return '';
    }
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">View Student Attendance</h1>

      {/* Class dropdown */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Select Class</label>
        <select
          onChange={handleClassChange}
          value={selectedClass}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        >
          <option value="">Select a class</option>
          <option value="Class 1">Class 1</option>
          {/* Add more class options as needed */}
        </select>
      </div>

      {selectedClass && (
        <div className="overflow-x-auto">
          {/* Table for attendance data */}
          <table className="w-full border border-gray-300 text-white bg-pink-300">
            <thead>
              <tr>
                <th className="border border-gray-300"></th>
                {daysOfWeek.map((day) => (
                  <th key={day} className="border border-gray-300 p-2 text-center">
                    {day}
                  </th>
                ))}
                <th className="border border-gray-300 p-2 text-center">Navigation</th>
              </tr>
            </thead>
            <tbody>
              {attendanceData
                .filter((student) => student.className === selectedClass)
                .map((student) => (
                  <tr key={student.id} className="border border-gray-300 transition-all">
                    <td className="border border-gray-300 p-2 flex flex-col items-center">
                      <img
                        src={student.photoUrl}
                        alt={student.name}
                        className="h-8 w-8 object-cover rounded-full mb-2"
                      />
                      <div>{student.name}</div>
                      <div>{`Roll No: ${student.rollNo}`}</div>
                    </td>
                    {daysOfWeek.map((day, dayIndex) => (
                      <td
                        key={day}
                        className={`border border-gray-300 p-2 text-center transition-all ${getBackgroundColor(
                          getAttendanceForDay(student, dayIndex)
                        )}`}
                      >
                        {getAttendanceForDay(student, dayIndex)}
                      </td>
                    ))}
                    <td className="border border-gray-300 p-2 text-center">
                      <button
                        onClick={() => handlePrevWeek(student.id)}
                        className="text-blue-500 focus:outline-none transition-all"
                        title="Previous Week"
                      >
                        &#9664;
                      </button>
                      <button
                        onClick={() =>
                          handleNextWeek(student.id, getMaxAttendanceLength(attendanceData, selectedClass))
                        }
                        className="text-blue-500 focus:outline-none ml-2 transition-all"
                        title="Next Week"
                      >
                        &#9654;
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ViewAttendance;
