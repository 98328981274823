// SaleStockEntry.js

import React, { useState } from "react";
import Modal from "react-modal";

// Dummy data for products and students
const dummyProducts = ["Product1", "Product2", "Product3"];
const dummyStudents = ["101-John-Doe", "102-Jane-Smith", "103-Bob-Jones"];

const SaleStockEntry = () => {
  const [billNo, setBillNo] = useState("DummyBillNo");
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [selectedProduct, setSelectedProduct] = useState("");
  const [quantity, setQuantity] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [rate, setRate] = useState("");
  const [totalPayable, setTotalPayable] = useState("");
  const [totalPaid, setTotalPaid] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [tableData, setTableData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleAddToTable = () => {
    const newRow = {
      billNo,
      date,
      selectedProduct,
      quantity,
      selectedStudent,
      rate,
      totalPayable,
      totalPaid,
      paymentMethod,
      dueAmount,
    };

    setTableData([...tableData, newRow]);
    // Reset form fields
    setBillNo("DummyBillNo");
    setDate(new Date().toLocaleDateString());
    setSelectedProduct("");
    setQuantity("");
    setSelectedStudent("");
    setRate("");
    setTotalPayable("");
    setTotalPaid("");
    setPaymentMethod("");
    setDueAmount("");
  };

  const handleEdit = (rowId) => {
    const selectedRow = tableData[rowId];
    setBillNo(selectedRow.billNo);
    setDate(selectedRow.date);
    setSelectedProduct(selectedRow.selectedProduct);
    setQuantity(selectedRow.quantity);
    setSelectedStudent(selectedRow.selectedStudent);
    setRate(selectedRow.rate);
    setTotalPayable(selectedRow.totalPayable);
    setTotalPaid(selectedRow.totalPaid);
    setPaymentMethod(selectedRow.paymentMethod);
    setDueAmount(selectedRow.dueAmount);
    setSelectedRowId(rowId);
    setIsModalOpen(true);
  };

  const handleSaveEdit = () => {
    const updatedRow = {
      billNo,
      date,
      selectedProduct,
      quantity,
      selectedStudent,
      rate,
      totalPayable,
      totalPaid,
      paymentMethod,
      dueAmount,
    };

    const updatedTableData = [...tableData];
    updatedTableData[selectedRowId] = updatedRow;

    setTableData(updatedTableData);
    setIsModalOpen(false);
  };

  const handleSave = () => {
    // Implement logic to save the complete object or further processing
    console.log("Saved data:", tableData);
  };

  return (
    <>
        <h1 className="text-3xl font-bold mb-6 text-center">Sale Stock Entry</h1>
    <div className="container mx-auto mt-8 p-4 bg-gray-100 w-full">
    
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div>
          <label className="block mb-2">Bill No:</label>
          <span className="mb-4 block">{billNo}</span>
        </div>
        <div>
          <label className="block mb-2">Date:</label>
          <input
            type="text"
            className="mb-4 border border-gray-300 p-2 w-full"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div>
          <label className="block mb-2">Product:</label>
          <select
            className="mb-4 border border-gray-300 p-2 w-full"
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
          >
            <option value="" disabled>
              Select Product
            </option>
            {dummyProducts.map((product) => (
              <option key={product} value={product}>
                {product}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block mb-2">Quantity:</label>
          <input
            type="text"
            className="mb-4 border border-gray-300 p-2 w-full"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </div>
        <div>
          <label className="block mb-2">Student:</label>
          <select
            className="mb-4 border border-gray-300 p-2 w-full"
            value={selectedStudent}
            onChange={(e) => setSelectedStudent(e.target.value)}
          >
            <option value="" disabled>
              Select Student
            </option>
            {dummyStudents.map((student) => (
              <option key={student} value={student}>
                {student}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block mb-2">Rate:</label>
          <input
            type="text"
            className="mb-4 border border-gray-300 p-2 w-full"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
          />
        </div>

        <div>
          <label className="block mb-2">Total Payable:</label>
          <input
            type="text"
            className="mb-4 border border-gray-300 p-2 w-full"
            value={totalPayable}
            readOnly
          />
        </div>

        <div>
          <label className="block mb-2">Total Paid:</label>
          <input
            type="text"
            className="mb-4 border border-gray-300 p-2 w-full"
            value={totalPaid}
            onChange={(e) => setTotalPaid(e.target.value)}
          />
        </div>

        <div>
          <label className="block mb-2">Due Amount:</label>
          <span className="mb-4 block">{dueAmount}</span>
        </div>

        <div>
          <label className="block mb-2">Payment Method:</label>
          <select
            className="mb-4 border border-gray-300 p-2 w-full"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="" disabled>
              Select Payment Method
            </option>
            <option value="Card">Card</option>
            <option value="UPI">UPI</option>
            <option value="Credit Card">Credit Card</option>
          </select>
        </div>

        <div className="mt-8">
          <button
            className="bg-blue-500 text-white px-4 py-2"
            onClick={handleAddToTable}
          >
            Add to Table
          </button>
        </div>
      </div>

      {/* Table to display entered data */}
      <table className="w-full mb-8">
        <thead>
          <tr>
            <th>Bill No</th>
            <th>Date</th>
            <th>Product</th>
            <th>Quantity</th>
            <th>Student</th>
            <th>Rate</th>
            <th>Total Payable</th>
            <th>Total Paid</th>
            <th>Due Amount</th>
            <th>Payment Method</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td>{row.billNo}</td>
              <td>{row.date}</td>
              <td>{row.selectedProduct}</td>
              <td>{row.quantity}</td>
              <td>{row.selectedStudent}</td>
              <td>{row.rate}</td>
              <td>{row.totalPayable}</td>
              <td>{row.totalPaid}</td>
              <td>{row.dueAmount}</td>
              <td>{row.paymentMethod}</td>
              <td>
                <button
                  className="bg-yellow-500 text-white px-2 py-1"
                  onClick={() => handleEdit(index)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Save button */}
      <button
        className="bg-green-500 text-white px-4 py-2"
        onClick={handleSave}
      >
        Save
      </button>

      {/* Modal for editing */}
      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <div className="bg-white p-8">
          <h2 className="text-lg font-semibold mb-4">Edit Entry</h2>

          <label className="block mb-2">Bill No:</label>
          <span className="mb-4 block">{billNo}</span>

          <label className="block mb-2">Date:</label>
          <input
            type="text"
            className="mb-4 border border-gray-300 p-2 w-full"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />

          <label className="block mb-2">Product:</label>
          <select
            className="mb-4 border border-gray-300 p-2 w-full"
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
          >
            <option value="" disabled>
              Select Product
            </option>
            {dummyProducts.map((product) => (
              <option key={product} value={product}>
                {product}
              </option>
            ))}
          </select>

          <label className="block mb-2">Quantity:</label>
          <input
            type="text"
            className="mb-4 border border-gray-300 p-2 w-full"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />

          <label className="block mb-2">Student:</label>
          <select
            className="mb-4 border border-gray-300 p-2 w-full"
            value={selectedStudent}
            onChange={(e) => setSelectedStudent(e.target.value)}
          >
            <option value="" disabled>
              Select Student
            </option>
            {dummyStudents.map((student) => (
              <option key={student} value={student}>
                {student}
              </option>
            ))}
          </select>

          <label className="block mb-2">Rate:</label>
          <input
            type="text"
            className="mb-4 border border-gray-300 p-2 w-full"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
          />

          <label className="block mb-2">Total Payable:</label>
          <input
            type="text"
            className="mb-4 border border-gray-300 p-2 w-full"
            value={totalPayable}
            readOnly
          />

          <label className="block mb-2">Total Paid:</label>
          <input
            type="text"
            className="mb-4 border border-gray-300 p-2 w-full"
            value={totalPaid}
            onChange={(e) => setTotalPaid(e.target.value)}
          />

          <label className="block mb-2">Due Amount:</label>
          <span className="mb-4 block">{dueAmount}</span>

          <label className="block mb-2">Payment Method:</label>
          <select
            className="mb-4 border border-gray-300 p-2 w-full"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="" disabled>
              Select Payment Method
            </option>
            <option value="Card">Card</option>
            <option value="UPI">UPI</option>
            <option value="Credit Card">Credit Card</option>
          </select>

          <button
            className="bg-blue-500 text-white px-4 py-2"
            onClick={handleSaveEdit}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
    </>
  );
};

export default SaleStockEntry;
