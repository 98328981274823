import React, { useState } from 'react';

const EditUser = () => {
  const [editedData, setEditedData] = useState({
    firstName: 'John', // Replace with actual data
    lastName: 'Doe', // Replace with actual data
    education: 'PhD', // Replace with actual data
    // ... other fields
  });

  const handleChange = (e) => {
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., API request to update user data
    console.log('Form submitted:', editedData);
  };

  return (
    <>
    <h2 className="text-3xl font-semibold m-4 text-center text-white">Edit User</h2>
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Render editable fields similar to AddNewUser component */}
        {/* For example: */}
        <label className="flex flex-col space-y-2">
          <span className="text-gray-700">First Name:</span>
          <input
            type="text"
            name="firstName"
            value={editedData.firstName}
            onChange={handleChange}
            className="border p-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </label>

        {/* ... other fields */}

        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300"
        >
          Save Changes
        </button>
      </form>
    </div>
    </>
  );
};

export default EditUser;
