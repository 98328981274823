// src/components/AddClass.js
import React, { useState } from 'react';

const AddClass = ({ onAddClass }) => {
  const [className, setClassName] = useState('');
  const [classTeacher, setClassTeacher] = useState('');

  const handleSubmit = () => {
    // Validate and submit class data
    if (className.trim() === '' || classTeacher.trim() === '') {
      alert('Please fill in all fields');
      return;
    }

    const newClass = {
      id: Math.floor(Math.random() * 1000), // Generate a random ID (replace with server-generated ID in real application)
      className,
      classTeacher,
    };

    // Pass the new class data to the parent component
    onAddClass(newClass);

    // Reset form fields
    setClassName('');
    setClassTeacher('');
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Add New Class</h2>
      <div className="mb-4">
        <label htmlFor="className" className="block text-sm font-medium text-gray-600">
          Class Name
        </label>
        <input
          type="text"
          id="className"
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="classTeacher" className="block text-sm font-medium text-gray-600">
          Class Teacher
        </label>
        <input
          type="text"
          id="classTeacher"
          value={classTeacher}
          onChange={(e) => setClassTeacher(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>
      <button onClick={handleSubmit} className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
        Add Class
      </button>
    </div>
  );
};

export default AddClass;
