// AdmitCard.js
import React, { useState } from "react";

const AdmitCard = () => {
  const data = [
    {
      id: 1,
      groupName: "Group 1",
      examType: "GPA grading system",
      exams: [
        {
          id: 1,
          title: "Exam 1",
          session: "Session 1",
          classData: [
            {
              className: "First",
              section: "A",
              students: [
                {
                  id: 1,
                  name: "Sam",
                  fatherName: "Joy",
                  motherName: "Natasha",
                  rollNo: 101,
                  dob: "11/10/2001",
                  photo:
                    "https://cdn.pixabay.com/photo/2024/01/15/19/40/animal-8510775_1280.jpg", // Replace with the actual photo URL
                },
                {
                  id: 2,
                  name: "Sam2",
                  fatherName: "Joy2",
                  motherName: "Natasha2",
                  rollNo: 102,
                  dob: "11/10/2001",
                  photo:
                    "https://cdn.pixabay.com/photo/2014/11/30/14/11/cat-551554_1280.jpg", // Replace with the actual photo URL
                },
              ],
              subjects: [
                {
                  id: 1,
                  name: "hindi",
                  date: "03/05/2024",
                  time: "20:40",
                  duration: 120,
                  MaxMarks: 200,
                  MinMarks: 60,
                },
                {
                  id: 2,
                  name: "hindi2",
                  date: "03/05/2024",
                  time: "20:40",
                  duration: 120,
                  MaxMarks: 200,
                  MinMarks: 60,
                },
              ],
            },
            {
              className: "First",
              section: "B",
              students: [
                {
                  id: 3,
                  name: "Sam3",
                  fatherName: "Joy3",
                  motherName: "Natasha3",
                  rollNo: 103,
                  dob: "11/10/2001",
                  photo:
                    "https://cdn.pixabay.com/photo/2017/11/09/21/41/cat-2934720_1280.jpg", // Replace with the actual photo URL
                },
                {
                  id: 4,
                  name: "Sam4",
                  fatherName: "Joy4",
                  motherName: "Natasha4",
                  rollNo: 104,
                  dob: "11/10/2001",
                  photo:
                    "https://cdn.pixabay.com/photo/2015/03/27/13/16/maine-coon-694730_1280.jpg", // Replace with the actual photo URL
                },
              ],
              subjects: [
                {
                  id: 3,
                  name: "hindi3",
                  date: "03/05/2024",
                  time: "20:40",
                  duration: 120,
                  MaxMarks: 200,
                  MinMarks: 60,
                },
                {
                  id: 4,
                  name: "hindi4",
                  date: "03/05/2024",
                  time: "20:40",
                  duration: 120,
                  MaxMarks: 200,
                  MinMarks: 60,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedExam, setSelectedExam] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  const handleGroupChange = (event) => {
    const groupId = event.target.value;
    const selectedGroup = data.find((group) => group.id === parseInt(groupId));
    setSelectedGroup(selectedGroup);
    setSelectedSession(null);
    setSelectedExam(null);
    setSelectedClass(null);
    setSelectedSection(null);
  };

  const handleSessionChange = (event) => {
    const session = event.target.value;
    setSelectedSession(session);
    setSelectedExam(null);
    setSelectedClass(null);
    setSelectedSection(null);
  };

  const handleExamChange = (event) => {
    const examId = event.target.value;
    const selectedExam = selectedGroup?.exams.find(
      (exam) => exam.id === parseInt(examId)
    );
    setSelectedExam(selectedExam);
    setSelectedClass(null);
    setSelectedSection(null);
  };

  const handleClassChange = (event) => {
    const className = event.target.value;
    setSelectedClass(className);
    setSelectedSection(null);
  };

  const handleSectionChange = (event) => {
    const section = event.target.value;
    setSelectedSection(section);
  };

  const handlePrint = (student) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <title>Admit Card</title>
      </head>
      <body>
        <div>
          <!-- Your admit card content for the selected student -->
          <p>${student.name}'s Admit Card</p>
          <!-- Include other details you want to show on the admit card -->
        </div>
      </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
  };

  return (
    <>
              <h1 className="text-3xl font-bold mb-4 text-center text-white">Generate Admit Card</h1>
    <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg shadow-md">
      <label htmlFor="groupSelect" className="block font-bold text-lg mb-2">
        Select a Group:
      </label>
      <select
        id="groupSelect"
        className="border p-2 w-full"
        onChange={handleGroupChange}
        value={selectedGroup ? selectedGroup.id : ""}
      >
        <option value="" disabled>
          Select a group
        </option>
        {data.map((group) => (
          <option key={group.id} value={group.id}>
            {group.groupName}
          </option>
        ))}
      </select>

      {selectedGroup && (
        <div className="mt-4">
          <label
            htmlFor="sessionSelect"
            className="block font-bold text-lg mb-2"
          >
            Select a Session:
          </label>
          <select
            id="sessionSelect"
            className="border p-2 w-full"
            onChange={handleSessionChange}
            value={selectedSession || ""}
          >
            <option value="" disabled>
              Select a session
            </option>
            {selectedGroup.exams.map((exam) => (
              <option key={exam.session} value={exam.session}>
                {exam.session}
              </option>
            ))}
          </select>

          <label
            htmlFor="examSelect"
            className="block font-bold text-lg mt-4 mb-2"
          >
            Select an Exam:
          </label>
          <select
            id="examSelect"
            className="border p-2 w-full"
            onChange={handleExamChange}
            value={selectedExam ? selectedExam.id : ""}
          >
            <option value="" disabled>
              Select an exam
            </option>
            {selectedGroup.exams.map((exam) => (
              <option key={exam.id} value={exam.id}>
                {exam.title}
              </option>
            ))}
          </select>

          {selectedExam && (
            <div className="mt-4">
              <label
                htmlFor="classSelect"
                className="block font-bold text-lg mb-2"
              >
                Select a Class:
              </label>
              <select
                id="classSelect"
                className="border p-2 w-full"
                onChange={handleClassChange}
                value={selectedClass || ""}
              >
                <option value="" disabled>
                  Select a class
                </option>
                {selectedExam.classData.map((classData) => (
                  <option key={classData.className} value={classData.className}>
                    {classData.className}
                  </option>
                ))}
              </select>

              <label
                htmlFor="sectionSelect"
                className="block font-bold text-lg mt-4 mb-2"
              >
                Select a Section:
              </label>
              <select
                id="sectionSelect"
                className="border p-2 w-full"
                onChange={handleSectionChange}
                value={selectedSection || ""}
              >
                <option value="" disabled>
                  Select a section
                </option>
                {selectedExam.classData.map((classData) => (
                  <option key={classData.section} value={classData.section}>
                    {classData.section}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}

      {selectedExam && selectedClass && selectedSection && (
        <div className="mt-8">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Admit Card For All Student</th>
              </tr>
            </thead>
            <tbody>
              {selectedExam.classData
                .find(
                  (classData) =>
                    classData.className === selectedClass &&
                    classData.section === selectedSection
                )
                .students.map((student) => (
                  <tr key={student.id}>
                    <td className="border px-4 py-2">
                      <div className="flex justify-between items-center">
                        <div>
                          <img
                            src={student.photo}
                            alt={`${student.name}'s photo`}
                            className="w-20 h-20 rounded-full mr-4"
                          />
                        </div>
                        <div>
                          <p className="px-4 py-2 font-bold text-xl mb-9">
                            Admit Card
                          </p>
                          <p className="font-bold text-xl">{student.name}</p>
                          <p>Father's Name: {student.fatherName}</p>
                          <p>Mother's Name: {student.motherName}</p>
                          <p>Roll No: {student.rollNo}</p>
                          <p>Date of Birth: {student.dob}</p>
                        </div>
                        <div>
                          {/* Add school logo here */}
                          <img
                            src="https://cdn.pixabay.com/photo/2023/12/09/10/09/woman-8438999_1280.png"
                            alt="School Logo"
                            className="w-20 h-20 ml-4 rounded-full"
                          />
                        </div>
                      </div>
                      <table className="mt-4 w-full">
                        <thead>
                          <tr>
                            <th className="px-4 py-2">Subject Name</th>
                            <th className="px-4 py-2">Date</th>
                            <th className="px-4 py-2">Duration</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedExam.classData
                            .find(
                              (classData) =>
                                classData.className === selectedClass &&
                                classData.section === selectedSection
                            )
                            .subjects.map((subject) => (
                              <tr key={subject.id}>
                                <td className="border px-4 py-2">
                                  {subject.name}
                                </td>
                                <td className="border px-4 py-2">
                                  {subject.date}
                                </td>
                                <td className="border px-4 py-2">
                                  {subject.duration}
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td>
                              <td className="border px-4 py-2">
                                <button
                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                  onClick={() => handlePrint(student)}
                                >
                                  Print Admit Card
                                </button>
                              </td>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
    </>
  );
};

export default AdmitCard;
