// src/components/CreateFeeGroup.js
import React, { useState } from 'react';

const CreateFeeGroup = ({ onCreateFeeGroup }) => {
  const [feeGroupName, setFeeGroupName] = useState('');
  const [amountPerMonth, setAmountPerMonth] = useState('');

  const handleCreateFeeGroup = () => {
    // Validate and create fee group
    if (feeGroupName.trim() === '' || amountPerMonth.trim() === '') {
      alert('Please fill in all fields');
      return;
    }

    const newFeeGroup = {
      id: Math.floor(Math.random() * 1000), // Generate a random ID (replace with server-generated ID in real application)
      feeGroupName,
      amountPerMonth,
    };

    // Pass the new fee group data to the parent component
    onCreateFeeGroup(newFeeGroup);

    // Reset form fields
    setFeeGroupName('');
    setAmountPerMonth('');
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Create Fee Group</h2>
      <div className="mb-4">
        <label htmlFor="feeGroupName" className="block text-sm font-medium text-gray-600">
          Fee Group Name
        </label>
        <input
          type="text"
          id="feeGroupName"
          value={feeGroupName}
          onChange={(e) => setFeeGroupName(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="amountPerMonth" className="block text-sm font-medium text-gray-600">
          Amount Per Month
        </label>
        <input
          type="text"
          id="amountPerMonth"
          value={amountPerMonth}
          onChange={(e) => setAmountPerMonth(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>
      <button onClick={handleCreateFeeGroup} className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
        Create Fee Group
      </button>
    </div>
  );
};

export default CreateFeeGroup;
