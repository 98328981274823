// src/components/ViewStudent.js
import React, { useState } from 'react';

const dummyStudents = [
  { id: 1, name: 'John Doe', rollNumber: '123456', photo: 'https://media.istockphoto.com/id/1189072996/vector/young-woman-goes-through-her-datebook-in-the-morning-and-plans-her-day.jpg?s=2048x2048&w=is&k=20&c=LONx2BxY_gS80rWhg6TPy1kVxUdWGxbZsb0qqTE8xYU=' },
  { id: 2, name: 'Jane Smith', rollNumber: '789012', photo: 'https://media.istockphoto.com/id/1189072996/vector/young-woman-goes-through-her-datebook-in-the-morning-and-plans-her-day.jpg?s=2048x2048&w=is&k=20&c=LONx2BxY_gS80rWhg6TPy1kVxUdWGxbZsb0qqTE8xYU=' },
  // Add more dummy data as needed
];

const ViewStudent = () => {
  const [editStudent, setEditStudent] = useState(null);
  const [newPhoto, setNewPhoto] = useState(null);
  const [newDocuments, setNewDocuments] = useState([]);

  const handleEditClick = (student) => {
    setEditStudent(student);
    setNewPhoto(null); // Reset new photo state
    setNewDocuments([]); // Reset new documents state
  };

  const handleFileUpload = (type, files) => {
    // Handle file uploads for photo and documents
    if (type === 'photo') {
      setNewPhoto(files[0]);
    } else if (type === 'documents') {
      setNewDocuments(files);
    }
  };

  const handleSaveEdit = () => {
    // Implement logic to save edited data
    console.log('Saving edited data:', editStudent, newPhoto, newDocuments);
    setEditStudent(null);
  };

  const handleCancelEdit = () => {
    setEditStudent(null);
  };

  return (
    <div className="max-w-2xl md:max-w-full mx-auto p-4 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">View Student</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-pink-400">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Photo</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Roll Number</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Actions</th>
            {/* Add more columns as needed */}
          </tr>
        </thead>
        <tbody>
          {dummyStudents.map((student) => (
            <tr key={student.id}>
              <td className="px-6 py-4">
                <img src={student.photo} alt={student.name} className="h-10 w-10 rounded-full" />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{student.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{student.rollNumber}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button onClick={() => handleEditClick(student)} className="text-blue-500 hover:underline focus:outline-none">
                  Edit
                </button>
              </td>
              {/* Add more columns as needed */}
            </tr>
          ))}
        </tbody>
      </table>

      {editStudent && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    {/* Edit icon or placeholder */}
                    Edit
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                      Edit Student Information
                    </h3>
                    {/* Form for editing student information */}
                    <form>
                      <div className="mb-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-600">
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={editStudent.name}
                          onChange={(e) => setEditStudent({ ...editStudent, name: e.target.value })}
                          className="mt-1 p-2 border rounded w-full"
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="rollNumber" className="block text-sm font-medium text-gray-600">
                          Roll Number
                        </label>
                        <input
                          type="text"
                          id="rollNumber"
                          name="rollNumber"
                          value={editStudent.rollNumber}
                          onChange={(e) => setEditStudent({ ...editStudent, rollNumber: e.target.value })}
                          className="mt-1 p-2 border rounded w-full"
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="newPhoto" className="block text-sm font-medium text-gray-600">
                          New Photo
                        </label>
                        <input
                          type="file"
                          id="newPhoto"
                          name="newPhoto"
                          onChange={(e) => handleFileUpload('photo', e.target.files)}
                          className="mt-1 p-2 border rounded"
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="newDocuments" className="block text-sm font-medium text-gray-600">
                          New Documents
                        </label>
                        <input
                          type="file"
                          id="newDocuments"
                          name="newDocuments"
                          multiple
                          onChange={(e) => handleFileUpload('documents', e.target.files)}
                          className="mt-1 p-2 border rounded"
                        />
                      </div>
                      {/* Add more fields as needed */}
                    </form>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleSaveEdit}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Save
                </button>
                <button
                  onClick={handleCancelEdit}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewStudent;
