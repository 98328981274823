// AddMarks.js
import React, { useState } from 'react';

const AddMarks = () => {
    const data = [
        {
          id: 1,
          groupName: 'Group 1',
          examType: 'GPA grading system',
          exams: [
            {
              title: 'Exam 1',
              session: 'Session 1',
              subjects: [
                {
                  name: 'hindi',
                  date: '03/05/2024',
                  time: '20:40',
                  duration: 120,
                  MaxMarks: 200,
                  MinMarks: 60,
                  marks: [
                    { rollNo: 101, obtainMarks: 40 },
                    { rollNo: 102, obtainMarks: 40 },
                    // Add more dummy marks as needed
                  ],
                },
                {
                    name: 'science',
                    date: '03/05/2024',
                    time: '20:40',
                    duration: 120,
                    MaxMarks: 200,
                    MinMarks: 60,
                    marks: [
                      { rollNo: 101, obtainMarks: 40 },
                      { rollNo: 102, obtainMarks: 40 },
                      // Add more dummy marks as needed
                    ],
                  },
              ],
            },
            {
                title: 'Exam 2',
                session: 'Session 1',
                subjects: [
                  {
                    name: 'hindi',
                    date: '03/05/2024',
                    time: '20:40',
                    duration: 120,
                    MaxMarks: 200,
                    MinMarks: 60,
                    marks: [
                      { rollNo: 101, obtainMarks: 40 },
                      { rollNo: 102, obtainMarks: 40 },
                      // Add more dummy marks as needed
                    ],
                  },
                  {
                      name: 'science',
                      date: '03/05/2024',
                      time: '20:40',
                      duration: 120,
                      MaxMarks: 200,
                      MinMarks: 60,
                      marks: [
                        { rollNo: 101, obtainMarks: 40 },
                        { rollNo: 102, obtainMarks: 40 },
                        // Add more dummy marks as needed
                      ],
                    },
                ],
              },
            // Add more exams as needed
          ],
        },
        {
          id: 2,
          groupName: 'Group 2',
          examType: 'CGPA grading system',
          exams: [
            {
              title: 'Exam 2',
              session: 'Session 2',
              subjects: [
                {
                  name: 'maths',
                  date: '03/10/2024',
                  time: '22:00',
                  duration: 90,
                  MaxMarks: 150,
                  MinMarks: 50,
                  marks: [
                    { rollNo: 103, obtainMarks: 35 },
                    { rollNo: 104, obtainMarks: 45 },
                    // Add more dummy marks as needed
                  ],
                },
                {
                    name: 'social sc',
                    date: '03/10/2024',
                    time: '22:00',
                    duration: 90,
                    MaxMarks: 150,
                    MinMarks: 50,
                    marks: [
                      { rollNo: 103, obtainMarks: 35 },
                      { rollNo: 104, obtainMarks: 45 },
                      // Add more dummy marks as needed
                    ],
                  },
                // Add more subjects as needed
              ],
            },
            // Add more exams as needed
          ],
        },
        // Add more groups as needed
      ];

 
      const [selectedGroup, setSelectedGroup] = useState(null);
      const [selectedExam, setSelectedExam] = useState(null);
      const [selectedSubject, setSelectedSubject] = useState(null);
      const [marksData, setMarksData] = useState([]);
    
      const handleGroupChange = (event) => {
        const groupId = parseInt(event.target.value);
        const selectedGroup = data.find((group) => group.id === groupId);
        setSelectedGroup(selectedGroup);
        setSelectedExam(null);
        setSelectedSubject(null);
        setMarksData([]);
      };
    
      const handleExamChange = (event) => {
        const examTitle = event.target.value;
        const selectedExam = selectedGroup.exams.find((exam) => exam.title === examTitle);
        setSelectedExam(selectedExam);
        setSelectedSubject(null);
        setMarksData([]);
      };
    
      const handleSubjectChange = (event) => {
        const subjectName = event.target.value;
        const selectedSubject = selectedExam.subjects.find((subject) => subject.name === subjectName);
        setSelectedSubject(selectedSubject);
        setMarksData(selectedSubject.marks);
      };
    
      const handleObtainMarksChange = (rollNo, newObtainMarks) => {
        const updatedMarksData = marksData.map((mark) =>
          mark.rollNo === rollNo ? { ...mark, obtainMarks: newObtainMarks } : mark
        );
        setMarksData(updatedMarksData);
      };
    
      const handleSave = () => {
        // Log the edited marks data to the console for now
        console.log('Edited Marks Data:', marksData);
      };
    
      return (
        <>
              <h1 className="text-3xl font-bold mb-4 text-center text-white">Add Marks</h1>
        <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg shadow-md">
          <label htmlFor="groupSelect" className="block font-bold text-lg mb-2">
            Select a Group:
          </label>
          <select
            id="groupSelect"
            className="border p-2 w-full"
            onChange={handleGroupChange}
            value={selectedGroup ? selectedGroup.id : ''}
          >
            <option value="" disabled>
              Select a group
            </option>
            {data.map((group) => (
              <option key={group.id} value={group.id}>
                {group.groupName}
              </option>
            ))}
          </select>
    
          {selectedGroup && (
            <div className="mt-4">
              <label htmlFor="examSelect" className="block font-bold text-lg mb-2">
                Select an Exam:
              </label>
              <select
                id="examSelect"
                className="border p-2 w-full"
                onChange={handleExamChange}
                value={selectedExam ? selectedExam.title : ''}
              >
                <option value="" disabled>
                  Select an exam
                </option>
                {selectedGroup.exams.map((exam) => (
                  <option key={exam.id} value={exam.title}>
                    {exam.title}
                  </option>
                ))}
              </select>
    
              {selectedExam && (
                <div className="mt-4">
                  <label htmlFor="subjectSelect" className="block font-bold text-lg mb-2">
                    Select a Subject:
                  </label>
                  <select
                    id="subjectSelect"
                    className="border p-2 w-full"
                    onChange={handleSubjectChange}
                    value={selectedSubject ? selectedSubject.name : ''}
                  >
                    <option value="" disabled>
                      Select a subject
                    </option>
                    {selectedExam.subjects.map((subject) => (
                      <option key={subject.name} value={subject.name}>
                        {subject.name}
                      </option>
                    ))}
                  </select>
    
                  {selectedSubject && (
                    <div className="mt-4">
                      <label className="block font-bold text-lg mb-2">Marks Data:</label>
                      <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                          <thead>
                            <tr>
                              <th className="border px-4 py-2">RollNo</th>
                              <th className="border px-4 py-2">Obtain Marks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {marksData.map((mark) => (
                              <tr key={mark.rollNo}>
                                <td className="border px-4 py-2">{mark.rollNo}</td>
                                <td className="border px-4 py-2">
                                  <input
                                    type="number"
                                    value={mark.obtainMarks}
                                    onChange={(e) =>
                                      handleObtainMarksChange(mark.rollNo, e.target.value)
                                    }
                                    className="w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:border-blue-300"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
    
                      <button
                        onClick={handleSave}
                        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        </>
      );
    };
    
    export default AddMarks;