import React, { useState } from 'react';

const PurchasedStockRecord = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [tableData, setTableData] = useState([
    {
      id: 1,
      date: '2024-01-01',
      product: 'Product A',
      vendor: 'Vendor X',
      quantity: 10,
      purchaseRate: 50,
      mrp: 60,
      totalPayable: 500,
      totalPaid: 300,
      paymentMethod: 'Cash',
      totalDue: 200,
      documents: [{ name: 'Document 1' }, { name: 'Document 2' }],
    },
    {
      id: 2,
      date: '2022-01-02',
      product: 'Product B',
      vendor: 'Vendor Y',
      quantity: 5,
      purchaseRate: 30,
      mrp: 40,
      totalPayable: 150,
      totalPaid: 100,
      paymentMethod: 'Card',
      totalDue: 50,
      documents: [{ name: 'Document 3' }],
    },
  ]);

  const handleFilterApply = () => {
    // Perform filtering logic here
    const filteredData = tableData
      .filter(
        (entry) =>
          entry.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
          entry.vendor.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .filter((entry) => {
        const entryDate = new Date(entry.date);
        const startFilterDate = startDate ? new Date(startDate) : null;
        const endFilterDate = endDate ? new Date(endDate) : null;

        if (startFilterDate && entryDate < startFilterDate) {
          return false;
        }

        if (endFilterDate && entryDate > endFilterDate) {
          return false;
        }

        return true;
      });

    // Update the state with the filtered data or perform any other action
    setTableData(filteredData);
  };


  return (
    <>
    <h1 className="text-3xl font-bold mb-6 text-center">Purchased Stock Record</h1>
    <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg shadow-lg">

      <div className="mb-4 flex justify-center items-center">
        <label className="text-sm font-medium text-gray-700 mr-4">Global Search:</label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4 flex justify-center items-center">
        <label className="text-sm font-medium text-gray-700 mr-4">Start Date:</label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        />
        <label className="text-sm font-medium text-gray-700 ml-4 mr-2">End Date:</label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        />
        <button
          onClick={handleFilterApply}
          className="ml-4 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
        >
          Apply Filter
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-md">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="py-2 px-4">ID</th>
              <th className="py-2 px-4">Date</th>
              <th className="py-2 px-4">Product</th>
              <th className="py-2 px-4">Vendor</th>
              <th className="py-2 px-4">Quantity</th>
              <th className="py-2 px-4">Purchase Rate</th>
              <th className="py-2 px-4">MRP</th>
              <th className="py-2 px-4">Total Payable</th>
              <th className="py-2 px-4">Total Paid</th>
              <th className="py-2 px-4">Payment Method</th>
              <th className="py-2 px-4">Total Due</th>
              <th className="py-2 px-4">Documents</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((entry) => (
              <tr key={entry.id} className="hover:bg-gray-200 transition duration-300">
                <td className="py-2 px-4">{entry.id}</td>
                <td className="py-2 px-4">{entry.date}</td>
                <td className="py-2 px-4">{entry.product}</td>
                <td className="py-2 px-4">{entry.vendor}</td>
                <td className="py-2 px-4">{entry.quantity}</td>
                <td className="py-2 px-4">{entry.purchaseRate}</td>
                <td className="py-2 px-4">{entry.mrp}</td>
                <td className="py-2 px-4">{entry.totalPayable}</td>
                <td className="py-2 px-4">{entry.totalPaid}</td>
                <td className="py-2 px-4">{entry.paymentMethod}</td>
                <td className="py-2 px-4">{entry.totalDue}</td>
                <td className="py-2 px-4">
                  {entry.documents.map((doc, index) => (
                    <span key={index} className="mr-2">
                      {doc.name}
                    </span>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
};

export default PurchasedStockRecord;
