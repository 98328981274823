import React, { useState } from 'react';

function Home() {
  const dummyData = {
    firstCardList: {
      1: {
        id: 1,
        title: 'Item 1',
        description: 'Description for Item 1',
      },
      2: {
        id: 2,
        title: 'Item 2',
        description: 'Description for Item 2',
      },
      3: {
        id: 3,
        title: 'Item 3',
        description: 'Description for Item 3',
      },
      4: {
        id: 4,
        title: 'Item 4',
        description: 'Description for Item 4',
      },
    },
    noticeBoardList: [
      {
        id: 1,
        title: 'Item 1',
        notice: 'Important notice for Item 1',
      },
      {
        id: 2,
        title: 'Item 2',
        notice: 'Important notice for Item 2',
      },
      {
        id: 3,
        title: 'Item 3',
        notice: 'Important notice for Item 3',
      },
      {
        id: 4,
        title: 'Item 4',
        notice: 'Important notice for Item 4',
      },
    ],
    toDoList: [
      {
        id: 1,
        title: 'Item 1',
        description: 'Description for Item 1',
      },
      {
        id: 2,
        title: 'Item 2',
        description: 'Description for Item 2',
      },
      {
        id: 3,
        title: 'Item 3',
        description: 'Description for Item 3',
      },
    ],
  };

  const [data, setData] = useState(dummyData);

  return (
    <>
    <h1 className="text-3xl font-bold mb-4 text-center text-white">Dashboard</h1>
    <div className="container mx-auto mt-4 p-4 bg-gray-100 rounded shadow-md">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {Object.keys(data.firstCardList).map((itemID) => (
          <div key={itemID} className="bg-gradient-to-r from-purple-500 to-blue-500 p-6 rounded-lg shadow-md text-white">
            <h2 className="text-xl font-bold mb-2">{data.firstCardList[itemID].title}</h2>
            <p>{data.firstCardList[itemID].description}</p>
          </div>
        ))}
      </div>
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="overflow-y-auto bg-gradient-to-r from-yellow-300 to-orange-500 p-6 rounded-lg shadow-md text-white">
          <h2 className="text-2xl font-bold mb-4">Notice Board</h2>
          <ul>
            {data.noticeBoardList.map((item) => (
              <li key={item.id} className="mb-2">
                <strong className="text-blue-500">{item.title}:</strong> {item.notice}
              </li>
            ))}
          </ul>
        </div>
        <div className="overflow-y-auto bg-gradient-to-r from-green-400 to-blue-500 p-6 rounded-lg shadow-md text-white">
          <h2 className="text-2xl font-bold mb-4">To-Do List</h2>
          <ul>
            {data.toDoList.map((item) => (
              <li key={item.id} className="mb-2">
                <strong className="text-green-500">{item.title}:</strong> {item.description}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </>
  );
}

export default Home;
