// SaleStockRecord.js

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SaleStockRecord = () => {
  // Dummy data for testing
  const dummyData = [
    {
      billNo: "D001",
      date: "2024-01-23",
      selectedProduct: "Product1",
      quantity: "5",
      selectedStudent: "101-John-Doe",
      rate: "10",
      totalPayable: "50",
      totalPaid: "30",
      paymentMethod: "Card",
      dueAmount: "20",
    },
    // Add more dummy data as needed
  ];



  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState(dummyData);

  const handleFilter = () => {
    const filteredResults = dummyData.filter((row) => {
      const rowDate = new Date(row.date);
      const isAfterStartDate = startDate ? rowDate >= startDate : true;
      const isBeforeEndDate = endDate ? rowDate <= endDate : true;
      return isAfterStartDate && isBeforeEndDate;
    });
    setFilteredData(filteredResults);
  };

  return (
    <>
      <h1 className="text-3xl font-bold mb-6 text-center">Sale Stock Records</h1>
    
    <div className="container mx-auto mt-8 p-4 bg-white w-full shadow-lg rounded-lg overflow-hidden">
      <div className="flex mb-4">
        <div className="mr-4">
          <label className="block text-sm font-medium text-gray-600">Start Date:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="border border-gray-300 p-2 w-48"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-600">End Date:</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="border border-gray-300 p-2 w-48"
          />
        </div>
        <button
          className="bg-blue-500 text-white px-4 py-2 ml-4 rounded-xl"
          onClick={handleFilter}
        >
          Apply Filter
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4 border border-gray-300">Sr No</th>
              <th className="py-2 px-4 border border-gray-300">Bill No</th>
              <th className="py-2 px-4 border border-gray-300">Date</th>
              <th className="py-2 px-4 border border-gray-300">Product Name</th>
              <th className="py-2 px-4 border border-gray-300">Student ID</th>
              <th className="py-2 px-4 border border-gray-300">Quantity</th>
              <th className="py-2 px-4 border border-gray-300">Payable Amount</th>
              <th className="py-2 px-4 border border-gray-300">Amount Paid</th>
              <th className="py-2 px-4 border border-gray-300">Payment Method</th>
              <th className="py-2 px-4 border border-gray-300">Remaining Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border border-gray-300">{index + 1}</td>
                <td className="py-2 px-4 border border-gray-300">{row.billNo}</td>
                <td className="py-2 px-4 border border-gray-300">{row.date}</td>
                <td className="py-2 px-4 border border-gray-300">{row.selectedProduct}</td>
                <td className="py-2 px-4 border border-gray-300">{row.selectedStudent}</td>
                <td className="py-2 px-4 border border-gray-300">{row.quantity}</td>
                <td className="py-2 px-4 border border-gray-300">{row.totalPayable}</td>
                <td className="py-2 px-4 border border-gray-300">{row.totalPaid}</td>
                <td className="py-2 px-4 border border-gray-300">{row.paymentMethod}</td>
                <td className="py-2 px-4 border border-gray-300">{row.dueAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
};

export default SaleStockRecord;
