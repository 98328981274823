import React, { useState } from "react";

const ViewExamTimetable = () => {
  const [selectedClass, setSelectedClass] = useState(null);

  const examData = [
    {
      id: 1,
      clsName: "First-A",
      examName: "Half Yearly",
      session: "2024-2025",
      details: [
        {
          date: "24/07/2024",
          day: "Mon",
          startTime: "08:30 AM",
          endTime: "11:30 AM",
          subject: "English",
          place: ["room1", "room2"],
        },
        {
          date: "25/07/2024",
          day: "Tue",
          startTime: "08:30 AM",
          endTime: "11:30 AM",
          subject: "Hindi",
          place: ["room1", "room2"],
        },
        // Add more entries if needed
      ],
    },
    {
      id: 2,
      clsName: "Second-A",
      examName: "Half Yearly",
      session: "2024-2025",
      details: [
        {
          date: "24/07/2024",
          day: "Mon",
          startTime: "08:30 AM",
          endTime: "11:30 AM",
          subject: "English",
          place: ["room1", "room2"],
        },
        {
          date: "25/07/2024",
          day: "Tue",
          startTime: "08:30 AM",
          endTime: "11:30 AM",
          subject: "Hindi",
          place: ["room1", "room2"],
        },
        // Add more entries if needed
      ],
    },
    // Add more classes if needed
  ];

  
  const handleClassChange = (event) => {
    const selectedClassName = event.target.value;
    const selectedClass = examData.find((cls) => cls.clsName === selectedClassName);
    setSelectedClass(selectedClass);
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg shadow-lg">
      <label className="text-lg font-semibold mb-2 block">Select Class:</label>
      <select
        className="border p-2 mb-4 w-full"
        onChange={handleClassChange}
        value={selectedClass ? selectedClass.clsName : ""}
      >
        <option value="" disabled>Select Class</option>
        {examData.map((cls) => (
          <option key={cls.id} value={cls.clsName}>
            {`${cls.clsName} - ${cls.examName} (${cls.session})`}
          </option>
        ))}
      </select>

      {selectedClass && (
        <div>
          <h2 className="text-2xl font-bold mb-4">
            {`${selectedClass.clsName} - ${selectedClass.examName} (${selectedClass.session})`}
          </h2>

          <div className="overflow-x-auto">
            <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-gray-200">
                  <th className="p-2">Date</th>
                  <th className="p-2">Day</th>
                  <th className="p-2">Time</th>
                  <th className="p-2">Subject</th>
                  <th className="p-2">Place</th>
                </tr>
              </thead>
              <tbody>
                {selectedClass.details.map((entry) => (
                  <tr key={entry.date + entry.startTime} className="text-center">
                    <td className="p-2">{entry.date}</td>
                    <td className="p-2">{entry.day}</td>
                    <td className="p-2">{entry.startTime} - {entry.endTime}</td>
                    <td className="p-2">{entry.subject}</td>
                    <td className="p-2">{entry.place.join(", ")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewExamTimetable;