import React, { useState } from 'react';

const ExamTimetable = () => {
  const [selectedGroup, setSelectedGroup] = useState('');

  const dummyData = [
    {
      id: 1,
      groupName: 'Group 1',
      examType: 'GPA grading system',
      exams: [
        {
          title: 'Exam 1',
          session: 'Session 1',
          subjects: [
            {
              name: 'Subject 1',
              date: '2024-01-01',
              time: '09:00',
              duration: 90,
              MaxMarks: 100,
              MinMarks: 40,
            },
            {
              name: 'Subject 2',
              date: '2024-01-02',
              time: '10:30',
              duration: 120,
              MaxMarks: 150,
              MinMarks: 60,
            },
          ],
        },
        {
          title: 'Exam 2',
          session: 'Session 2',
          subjects: [
            {
              name: 'Subject 3',
              date: '2024-01-03',
              time: '14:00',
              duration: 90,
              MaxMarks: 120,
              MinMarks: 50,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      groupName: 'Group 2',
      examType: 'General Purpose (pass/fail)',
      exams: [
        {
          title: 'Exam 3',
          session: 'Session 1',
          subjects: [
            {
              name: 'Subject 4',
              date: '2024-01-04',
              time: '11:00',
              duration: 120,
              MaxMarks: 80,
              MinMarks: 30,
            },
          ],
        },
      ],
    },
  ];

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const filteredData = selectedGroup
    ? dummyData.filter((group) => group.groupName === selectedGroup)
    : dummyData;

  return (
    <div className="exam-timetable max-w-screen-xl mx-auto p-8">
      <h2 className="text-3xl font-bold mb-4">Exam Timetable</h2>

      <div className="mb-4">
        <label htmlFor="groupFilter" className="mr-2">
          Select Group:
        </label>
        <select
          id="groupFilter"
          onChange={handleGroupChange}
          value={selectedGroup}
          className="border p-2 rounded-md"
        >
          <option value="">All Groups</option>
          {dummyData.map((group) => (
            <option key={group.id} value={group.groupName}>
              {group.groupName}
            </option>
          ))}
        </select>
      </div>

      <table className="border-collapse w-full mt-4">
        <thead>
          <tr>
            <th className="border p-2">Group Name</th>
            <th className="border p-2">Exam Type</th>
            <th className="border p-2">Exam Title</th>
            <th className="border p-2">Session</th>
            <th className="border p-2">Subject</th>
            <th className="border p-2">Date</th>
            <th className="border p-2">Time</th>
            <th className="border p-2">Duration (minutes)</th>
            <th className="border p-2">Max Marks</th>
            <th className="border p-2">Min Marks</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((group) =>
            group.exams.map((exam) =>
              exam.subjects.map((subject) => (
                <tr key={subject.name} className="bg-white border-b border-gray-200">
                  <td className="border p-2">{group.groupName}</td>
                  <td className="border p-2">{group.examType}</td>
                  <td className="border p-2">{exam.title}</td>
                  <td className="border p-2">{exam.session}</td>
                  <td className="border p-2">{subject.name}</td>
                  <td className="border p-2">{subject.date}</td>
                  <td className="border p-2">{subject.time}</td>
                  <td className="border p-2">{subject.duration}</td>
                  <td className="border p-2">{subject.MaxMarks}</td>
                  <td className="border p-2">{subject.MinMarks}</td>
                </tr>
              ))
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ExamTimetable;
