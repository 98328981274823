// MySalarySlip.js
import React, { useState } from 'react';

const MySalarySlip = () => {
  // Dummy data
  const years = [2022, 2023, 2024];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const salarySlips = [
    { year: 2023, month: 'January', uploadDate: '2023-01-05', fileName: 'salary_january_2023.pdf' },
    { year: 2023, month: 'February', uploadDate: '2023-02-05', fileName: 'salary_february_2023.pdf' },
    { year: 2023, month: 'March', uploadDate: '2023-02-05', fileName: 'salary_february_2023.pdf' },
    { year: 2023, month: 'April', uploadDate: '2023-02-05', fileName: 'salary_february_2023.pdf' },
    { year: 2023, month: 'May', uploadDate: '2023-02-05', fileName: 'salary_february_2023.pdf' },
    // Add more salary slips as needed
  ];

  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filteredSalarySlips = salarySlips.filter((slip) => {
    return (
      (selectedYear === '' || slip.year.toString() === selectedYear) &&
      (selectedMonth === '' || slip.month === selectedMonth)
    );
  });

  const handleView = (fileName) => {
    // In a real-world scenario, you might open a modal or redirect to a viewer.
    window.open(`https://example.com/${fileName}`, '_blank');
  };

  const handleDownload = (fileName) => {
    // In a real-world scenario, you might trigger a download.
    window.location.href = `https://example.com/${fileName}`;
  };

  return (
    <>
          <h1 className="text-4xl font-bold mb-8 text-center">My Salary Slip</h1>
          <div className="container mx-auto p-8 bg-white">

      {/* Filter Dropdowns */}
      <div className="flex justify-center space-x-4 mb-8">
        <div>
          <label htmlFor="year" className="text-sm font-medium text-gray-600 block">
            Select Year:
          </label>
          <select
            id="year"
            name="year"
            value={selectedYear}
            onChange={handleYearChange}
            className="mt-1 p-2 border rounded"
          >
            <option value="">-- All Years --</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="month" className="text-sm font-medium text-gray-600 block">
            Select Month:
          </label>
          <select
            id="month"
            name="month"
            value={selectedMonth}
            onChange={handleMonthChange}
            className="mt-1 p-2 border rounded"
          >
            <option value="">-- All Months --</option>
            {months.map((month, index) => (
              <option key={index} value={month}>
                {month}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Salary Slip Table */}
      <table className="w-full bg-white border border-gray-300 shadow-lg rounded-md overflow-hidden">
        <thead className="bg-gray-200">
          <tr>
            <th className="py-2 px-4 border-b text-left">Year</th>
            <th className="py-2 px-4 border-b text-left">Month</th>
            <th className="py-2 px-4 border-b text-left">Uploaded Date</th>
            <th className="py-2 px-4 border-b text-left">File Name</th>
            <th className="py-2 px-4 border-b text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredSalarySlips.map((slip, index) => (
            <tr key={index} className={(index % 2 === 0) ? 'bg-gray-100' : 'bg-white'}>
              <td className="py-2 px-4 border-b">{slip.year}</td>
              <td className="py-2 px-4 border-b">{slip.month}</td>
              <td className="py-2 px-4 border-b">{slip.uploadDate}</td>
              <td className="py-2 px-4 border-b">{slip.fileName}</td>
              <td className="py-2 px-4 border-b">
                <button
                  className="bg-blue-500 text-white py-1 px-2 rounded mr-2"
                  onClick={() => handleView(slip.fileName)}
                >
                  View
                </button>
                <button
                  className="bg-green-500 text-white py-1 px-2 rounded"
                  onClick={() => handleDownload(slip.fileName)}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
    
  );
};

export default MySalarySlip;
