// src/components/SalaryCalculator.js
import React, { useState } from 'react';

const SalaryCalculator = () => {
  const [employeeId, setEmployeeId] = useState('');
  const [basicSalary, setBasicSalary] = useState('');
  const [allowances, setAllowances] = useState('');
  const [deductions, setDeductions] = useState('');

  const calculateSalary = () => {
    // Implement logic to calculate salary based on inputs
    const totalSalary = parseFloat(basicSalary) + parseFloat(allowances) - parseFloat(deductions);
    console.log('Salary Calculation:', { employeeId, totalSalary });
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Salary Calculator</h2>
      <div className="mb-4">
        <label htmlFor="employeeId" className="block text-sm font-medium text-gray-600">
          Employee ID
        </label>
        <input
          type="text"
          id="employeeId"
          value={employeeId}
          onChange={(e) => setEmployeeId(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="basicSalary" className="block text-sm font-medium text-gray-600">
          Basic Salary
        </label>
        <input
          type="text"
          id="basicSalary"
          value={basicSalary}
          onChange={(e) => setBasicSalary(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="allowances" className="block text-sm font-medium text-gray-600">
          Allowances
        </label>
        <input
          type="text"
          id="allowances"
          value={allowances}
          onChange={(e) => setAllowances(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="deductions" className="block text-sm font-medium text-gray-600">
          Deductions
        </label>
        <input
          type="text"
          id="deductions"
          value={deductions}
          onChange={(e) => setDeductions(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>
      <button onClick={calculateSalary} className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
        Calculate Salary
      </button>
    </div>
  );
};

export default SalaryCalculator;
