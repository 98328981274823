import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import Main from "./components/main/Main";
import Login from "./components/login/Login";
import Profile from "./components/profile/Profile";
import AddNewUser from "./components/user/AddNewUser";
import EditUser from "./components/user/EditUser";
import Users from "./components/user/Users";
import TeamPage from "./components/team/TeamPage";
import TeamAttendancePage from "./components/attendance/TeamAttendancePage";
import MyAttendancePage from "./components/attendance/MyAttendancePage";
import ManageNoticePage from "./components/notice/ManageNoticePage";
import ViewNoticePage from "./components/notice/ViewNoticePage";
import ViewTimetable from "./components/timetable/ViewTimetable";
import ViewExamTimetable from "./components/timetable/ViewExamTimetable";
import TimetableEditor from "./components/timetable/TimetableEditor";
import AddStudent from "./components/student/AddStudent";
import ViewStudent from "./components/student/ViewStudent";
import AddClass from "./components/class/AddClass";
import ViewClasses from "./components/class/ViewClasses";
import AddSubject from "./components/subject/AddSubject";
import ViewSubjects from "./components/subject/ViewSubjects";
import FeePaymentEntry from "./components/account/FeePaymentEntry";
import SalaryCalculator from "./components/account/SalaryCalculator";
import CreateFeeGroup from "./components/account/CreateFeeGroup";
import ViewFeeGroups from "./components/account/ViewFeeGroups";
import MySalarySlip from "./components/account/MySalarySlip";
import StudnetAttendanceTracker from "./components/attendance/StudentAttendanceTracker";
import ViewAttendance from "./components/attendance/ViewAttendance";
import Vendors from "./components/inventory/Vendors";
import Products from "./components/inventory/Products";
import PurchasedStockEntry from "./components/inventory/PurchasedStockEntry";
import PurchasedStockRecord from "./components/inventory/PurchasedStockRecord";
import SaleStockRecords from "./components/inventory/SaleStockRecords";
import SaleStockEntry from "./components/inventory/SaleStockEntry";
import Stocks from "./components/inventory/Stock";
import ExamGroupManager from "./components/exam/ExamGroupManager";
import ExamTimetable from "./components/exam/ExamTimetable";
import AddMarks from "./components/exam/AddMarks";
import AdmitCard from "./components/exam/AdmitCard";
import Marksheet from "./components/exam/Marksheet";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/"  element={<Main><Home/></Main> } />
        <Route path="/profile"  element={<Main><Profile/></Main> } />
        <Route path="/user/add"  element={<Main><AddNewUser/></Main> } />
        <Route path="/user/edit"  element={<Main><EditUser/></Main> } />
        <Route path="/user/all"  element={<Main><Users/></Main> } />
        <Route path="/team"  element={<Main><TeamPage/></Main> } />
        <Route path="/attendance/team"  element={<Main><TeamAttendancePage/></Main> } />
        <Route path="/attendance/my"  element={<Main><MyAttendancePage/></Main> } />
        <Route path="/attendance/student/tracker"  element={<Main><StudnetAttendanceTracker/></Main> } />
        <Route path="/notice/manage"  element={<Main><ManageNoticePage/></Main> } />
        <Route path="/notice/view"  element={<Main><ViewNoticePage/></Main> } />
        <Route path="/timetable/view"  element={<Main><ViewTimetable/></Main> } />
        <Route path="/timetable/Exam-view"  element={<Main><ViewExamTimetable/></Main> } />
        <Route path="/timetable/editor"  element={<Main><TimetableEditor/></Main> } />
        <Route path="/student/add"  element={<Main><AddStudent/></Main> } />
        <Route path="/student/view"  element={<Main><ViewStudent/></Main> } />
        <Route path="/class/add"  element={<Main><AddClass/></Main> } />
        <Route path="/class/view"  element={<Main><ViewClasses/></Main> } />
        <Route path="/subject/add"  element={<Main><AddSubject/></Main> } />
        <Route path="/subject/view"  element={<Main><ViewSubjects/></Main> } />
        <Route path="/account/payfee"  element={<Main><FeePaymentEntry/></Main> } />
        <Route path="/account/fee-group/add"  element={<Main><CreateFeeGroup/></Main> } />
        <Route path="/account/fee-group/view"  element={<Main><ViewFeeGroups/></Main> } />
        <Route path="/account/salary-calculator"  element={<Main><SalaryCalculator/></Main> } />
        <Route path="/account/my-salary-slip"  element={<Main><MySalarySlip/></Main> } />
        <Route path="/attendance/student/view"  element={<Main><ViewAttendance/></Main> } />
        <Route path="/inventory/vendors"  element={<Main><Vendors/></Main> } />
        <Route path="/inventory/products"  element={<Main><Products/></Main> } />
        <Route path="/inventory/purchased-stock-entry"  element={<Main><PurchasedStockEntry/></Main> } />
        <Route path="/inventory/purchased-stock-records"  element={<Main><PurchasedStockRecord/></Main> } />
        <Route path="/inventory/sale-stock-records"  element={<Main><SaleStockRecords/></Main> } />
        <Route path="/inventory/sale-stock-entry"  element={<Main><SaleStockEntry/></Main> } />
        <Route path="/inventory/stocks"  element={<Main><Stocks/></Main> } />
        <Route path="/exam/exam-group-manager"  element={<Main><ExamGroupManager/></Main> } />
        <Route path="/exam/time-table"  element={<Main><ExamTimetable/></Main> } />
        <Route path="/exam/add-marks"  element={<Main><AddMarks/></Main> } />
        <Route path="/exam/admit-card"  element={<Main><AdmitCard/></Main> } />
        <Route path="/exam/marksheet"  element={<Main><Marksheet/></Main> } />
        <Route path="/login"  element={<Login/> } />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
