import React from "react";

function Header() {
  return (
    <header className="bg-blue-500 text-white p-4">
      <div className="w-full md:h-20 h-36 flex md:justify-center items-center">
        <div className="md:flex md:order-0 md:ml-auto">         
          <img className="h-20 w-20 rounded-full mx-auto " src="https://cdn.pixabay.com/photo/2023/12/09/10/09/woman-8438999_1280.png" alt="Logo"/>
          <div className="ml-1 text-white p-2">
            <p className="text-xl font-bold">School Name is here</p>
            <p className="text-center">Address</p>
          </div>
        </div>
        <div className="flex order-1 ml-auto">
          <div className="p-2 hover:bg-white cursor-pointer">&#x1F514;</div>
          <button className='hidden md:inline-block md:bg-sky-500 md:mr-2 md:rounded-xl md:text-white md:font-medium md:p-2 md:hover:bg-white md:hover:text-black'> Logout</button>
        </div>
      </div>
    </header>
  );
}

export default Header;
