// AvailableStock.js

import React, { useState } from "react";

const Stocks = () => {
  // Dummy data for testing
  const dummyData = [
    { itemName: "Item1", totalPurchased: 100, totalSale: 50, totalAvailable: 50 },
    { itemName: "Item2", totalPurchased: 150, totalSale: 75, totalAvailable: 75 },
    { itemName: "Item3", totalPurchased: 200, totalSale: 100, totalAvailable: 100 },
    // Add more dummy data as needed
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(dummyData);

  const handleSearch = () => {
    const searchTermLower = searchTerm.toLowerCase();
    const filteredResults = dummyData.filter((row) =>
      row.itemName.toLowerCase().includes(searchTermLower)
    );
    setFilteredData(filteredResults);
  };

  return (
    <>
    <h1 className="text-3xl font-bold mb-6 text-center">Available Stock</h1>
    <div className="container mx-auto mt-8 p-4 bg-white w-full shadow-lg rounded-lg overflow-hidden">
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600">Search:</label>
        <input
          type="text"
          className="border border-gray-300 p-2 w-64"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className="bg-blue-500 text-white px-4 py-2 ml-4"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4 border border-gray-300">Sr No</th>
              <th className="py-2 px-4 border border-gray-300">Item Name</th>
              <th className="py-2 px-4 border border-gray-300">Total Purchased</th>
              <th className="py-2 px-4 border border-gray-300">Total Sale</th>
              <th className="py-2 px-4 border border-gray-300">Total Available</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border border-gray-300">{index + 1}</td>
                <td className="py-2 px-4 border border-gray-300">{row.itemName}</td>
                <td className="py-2 px-4 border border-gray-300">{row.totalPurchased}</td>
                <td className="py-2 px-4 border border-gray-300">{row.totalSale}</td>
                <td className="py-2 px-4 border border-gray-300">{row.totalAvailable}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
};

export default Stocks;
