// import React, { useState, useEffect } from 'react';

// const pageSize = 5; // Number of users to display per page

// const Users = () => {
//   const [users, setUsers] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);

//   useEffect(() => {
//     // Dummy data for demonstration purposes
//     const dummyData = [
//       {
//         id: 1,
//         firstName: 'John',
//         lastName: 'Doe',
//         education: 'PhD in Computer Science',
//         photoUrl: 'https://randomuser.me/api/portraits/men/1.jpg',
//       },
//       {
//         id: 2,
//         firstName: 'Jane',
//         lastName: 'Smith',
//         education: 'Master of Business Administration',
//         photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
//       },
//       {
//         id: 3,
//         firstName: 'Jane',
//         lastName: 'Smith',
//         education: 'Master of Business Administration',
//         photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
//       },{
//         id: 4,
//         firstName: 'Jane',
//         lastName: 'Smith',
//         education: 'Master of Business Administration',
//         photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
//       },{
//         id: 5,
//         firstName: 'Jane',
//         lastName: 'Smith',
//         education: 'Master of Business Administration',
//         photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
//       },{
//         id: 6,
//         firstName: 'Jane',
//         lastName: 'Smith',
//         education: 'Master of Business Administration',
//         photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
//       },{
//         id: 7,
//         firstName: 'Jane',
//         lastName: 'Smith',
//         education: 'Master of Business Administration',
//         photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
//       },
//       {
//         id: 8,
//         firstName: 'Jane',
//         lastName: 'Smith',
//         education: 'Master of Business Administration',
//         photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
//       },
//       // Add more dummy data as needed
//     ];

//     setUsers(dummyData);
//   }, []);

//   const totalPages = Math.ceil(users.length / pageSize);

//   const handlePageChange = (newPage) => {
//     if (newPage >= 1 && newPage <= totalPages) {
//       setCurrentPage(newPage);
//     }
//   };

//   const startIndex = (currentPage - 1) * pageSize;
//   const displayedUsers = users.slice(startIndex, startIndex + pageSize);

//   return (
//     <div className="md:max-w-2xl md:mx-auto md:mt-8 md:p-6 bg-white rounded-md shadow-md">
//       <h2 className="md:text-2xl text-xl md:text-left text-center font-bold mb-6">View Users</h2>
//       <table className="w-full border-collapse border">
//         <thead>
//           <tr className="bg-gray-200">
//             <th className="py-2 px-4 border">Name</th>
//             <th className="py-2 px-4 border">Education</th>
//             <th className="py-2 px-4 border">Profile Photo</th>
//             {/* Add more columns as needed */}
//           </tr>
//         </thead>
//         <tbody>
//           {displayedUsers.map((user) => (
//             <tr key={user.id} className="border">
//               <td className="py-2 px-4 border">{`${user.firstName} ${user.lastName}`}</td>
//               <td className="py-2 px-4 border">{user.education}</td>
//               <td className="py-2 px-4 border">
//                 <img
//                   src={user.photoUrl}
//                   alt={`${user.firstName} ${user.lastName}`}
//                   className="w-10 h-10 rounded-full"
//                 />
//               </td>
//               {/* Add more columns as needed */}
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Pagination */}
//       <div className="flex justify-end mt-4">
//         <button
//           onClick={() => handlePageChange(currentPage - 1)}
//           className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
//           disabled={currentPage === 1}
//         >
//           Previous
//         </button>
//         <button
//           onClick={() => handlePageChange(currentPage + 1)}
//           className="px-4 py-2 bg-blue-500 text-white rounded-md"
//           disabled={currentPage === totalPages}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Users;


//2

import React, { useState, useEffect } from 'react';

const pageSize = 8; // Number of users to display per page

const Users = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Dummy data for demonstration purposes
    const dummyData = [
      {
        id: 1,
        firstName: 'John',
        lastName: 'Doe',
        education: 'PhD in Computer Science',
        photoUrl: 'https://randomuser.me/api/portraits/men/1.jpg',
      },
      {
        id: 2,
        firstName: 'Jane',
        lastName: 'Smith',
        education: 'Master of Business Administration',
        photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
      },
      {
        id: 3,
        firstName: 'Jane',
        lastName: 'Smith',
        education: 'Master of Business Administration',
        photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
      },
      {
        id: 4,
        firstName: 'Jane',
        lastName: 'Smith',
        education: 'Master of Business Administration',
        photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
      },
      {
        id: 5,
        firstName: 'Jane',
        lastName: 'Smith',
        education: 'Master of Business Administration',
        photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
      },
      {
        id: 6,
        firstName: 'Jane',
        lastName: 'Smith',
        education: 'Master of Business Administration',
        photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
      },
      {
        id: 7,
        firstName: 'Jane',
        lastName: 'Smith',
        education: 'Master of Business Administration',
        photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
      },
      {
        id: 8,
        firstName: 'Jane',
        lastName: 'Smith',
        education: 'Master of Business Administration',
        photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
      },
      {
        id: 9,
        firstName: 'Jane',
        lastName: 'Smith',
        education: 'Master of Business Administration',
        photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
      },{
        id: 10,
        firstName: 'Jane',
        lastName: 'Smith',
        education: 'Master of Business Administration',
        photoUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
      },
      // Add more dummy data as needed
    ];

    setUsers(dummyData);
  }, []);

  const totalPages = Math.ceil(users.length / pageSize);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const startIndex = (currentPage - 1) * pageSize;
  const displayedUsers = users.slice(startIndex, startIndex + pageSize);

  return (
    <div className="max-w-full mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">View Users</h2>

      {/* Responsive card layout for mobile view */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {displayedUsers.map((user) => (
          <div key={user.id} className="bg-gray-100 p-4 rounded-md shadow-md">
            <img
              src={user.photoUrl}
              alt={`${user.firstName} ${user.lastName}`}
              className="w-20 h-20 rounded-full mx-auto mb-2"
            />
            <div className="text-center">
              <p className="text-lg font-bold">
                {user.firstName} {user.lastName}
              </p>
              <p className="text-gray-500">{user.education}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="flex justify-end mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-4 py-2 bg-blue-500 text-white rounded-md"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Users;

