// src/MyAttendancePage.js
import React, { useState } from 'react';

const MyAttendancePage = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [attendanceType, setAttendanceType] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle form submission
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-4 bg-white shadow-md rounded">
    <h1 className="text-2xl mb-4">My Attendance Page</h1>
    <form onSubmit={handleFormSubmit}>
      <label className="block mb-2">
        Start Date:
        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="border rounded px-2 py-1 mt-1 w-full" />
      </label>
      <label className="block mb-2">
        End Date:
        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="border rounded px-2 py-1 mt-1 w-full" />
      </label>
      <label className="block mb-2">
        Attendance Type:
        <select value={attendanceType} onChange={(e) => setAttendanceType(e.target.value)} className="border rounded px-2 py-1 mt-1 w-full">
          <option value="present">Present</option>
          <option value="absent">Absent</option>
          {/* Add other attendance types */}
        </select>
      </label>
      <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Submit</button>
    </form>
  </div>
  );
};

export default MyAttendancePage;
