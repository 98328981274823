// SubjectDetails.js
import React from 'react';

const SubjectDetails = ({ subjects }) => {
  return (
    <table className="border-collapse w-full">
      <thead>
        <tr>
          <th className="border p-2">Subject</th>
          <th className="border p-2">Date</th>
          <th className="border p-2">Time</th>
          <th className="border p-2">Duration (minutes)</th>
          <th className="border p-2">Max Marks</th>
          <th className="border p-2">Min Marks</th>
        </tr>
      </thead>
      <tbody>
        {subjects.map((subject, index) => (
          <tr key={index}>
            <td className="border p-2">{subject.subject}</td>
            <td className="border p-2">{subject.date}</td>
            <td className="border p-2">{subject.time}</td>
            <td className="border p-2">{subject.duration}</td>
            <td className="border p-2">{subject.marksMax}</td>
            <td className="border p-2">{subject.marksMin}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SubjectDetails;
