// Import necessary libraries
import React, { useState } from 'react';

// Sample student data with photo links from Lorem Picsum
const studentData = [
  { id: 1, name: 'John Doe', rollNo: '101', className: 'Class 1', photoUrl: 'https://picsum.photos/200/300?random=1' },
  { id: 2, name: 'Jane Smith', rollNo: '102', className: 'Class 2', photoUrl: 'https://picsum.photos/200/300?random=2' },
  { id: 3, name: 'Jane Smith', rollNo: '103', className: 'Class 2', photoUrl: 'https://picsum.photos/200/300?random=2' },
  { id: 4, name: 'Jane Smith', rollNo: '104', className: 'Class 2', photoUrl: 'https://picsum.photos/200/300?random=2' },
  { id: 5, name: 'Jane Smith', rollNo: '105', className: 'Class 2', photoUrl: 'https://picsum.photos/200/300?random=2' },
  // Add more student data as needed
];

// Attendance component
const StudentAttendanceTracker = () => {
  // State to track selected class
  const [selectedClass, setSelectedClass] = useState('');
  // State to track selected button for each student card
  const [selectedButton, setSelectedButton] = useState({});

  // Function to handle class dropdown change
  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    // Reset selected button when class changes
    setSelectedButton({});
  };

  // Function to handle attendance button click
  const handleAttendance = (studentId, status) => {
    // Update the selected button for the clicked student
    setSelectedButton((prevSelectedButton) => ({
      ...prevSelectedButton,
      [studentId]: status,
    }));
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Attendance Tracker</h2>

      {/* Class dropdown */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Select Class</label>
        <select
          onChange={handleClassChange}
          value={selectedClass}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        >
          {/* Add class options dynamically based on your classes */}
          <option value="">Select a class</option>
          <option value="Class 1">Class 1</option>
          <option value="Class 2">Class 2</option>
          {/* Add more class options as needed */}
        </select>
      </div>

      {/* Student cards */}
      {selectedClass && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {studentData
            .filter((student) => student.className === selectedClass)
            .map((student) => (
              <div key={student.id} className="bg-white p-4 rounded-md shadow-md">
                <img
                  src={student.photoUrl}
                  alt={student.name}
                  className="h-32 w-full object-cover mb-4 rounded-md"
                />
                <h3 className="text-lg font-semibold mb-2">{student.name}</h3>
                <p className="text-gray-500 mb-2">{`Roll No: ${student.rollNo}`}</p>
                <div className="flex justify-around">
                  <button
                    onClick={() => handleAttendance(student.id, 'absent')}
                    className={`bg-red-500 text-white px-2 py-1 rounded`}
                  >
                    Absent
                  </button>
                  <button
                    onClick={() => handleAttendance(student.id, 'present')}
                    className={`bg-green-500 text-white px-2 py-1 rounded`}
                  >
                    Present
                  </button>
                  <button
                    onClick={() => handleAttendance(student.id, 'onleave')}
                    className={`bg-blue-500 text-white px-2 py-1 rounded`}
                  >
                    On Leave
                  </button>
                </div>
                {/* Hide other buttons when one button is clicked */}
                {selectedButton[student.id] && (
                  <div className="mt-2">
                    {selectedButton[student.id] === 'absent' && <p className='text-xl font-bold text-white bg-red-500 text-center'>Marked as Absent</p>}
                    {selectedButton[student.id] === 'present' && <p className='text-xl font-bold text-white bg-green-500 text-center'>Marked as Present</p>}
                    {selectedButton[student.id] === 'onleave' && <p className='text-xl font-bold text-white bg-blue-500 text-center'>Marked as On Leave</p>}
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};


export default StudentAttendanceTracker;
