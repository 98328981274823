// src/components/AddStudent.js
import React, { useState } from 'react';
//import axios from 'axios';

const AddStudent = () => {
  const [photo, setPhoto] = useState(null);
  const [documents, setDocuments] = useState([]);

  const handlePhotoUpload = (file) => {
    setPhoto(file);
  };

  const handleDocumentUpload = (files) => {
    setDocuments(files);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('photo', photo);
    documents.forEach((doc) => formData.append('documents', doc));

    // try {
    //   await axios.post('/api/addStudent', formData);
    //   // Handle success
    // } catch (error) {
    //   // Handle error
    // }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Add Student</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600">Upload Photo:</label>
        <input type="file" onChange={(e) => handlePhotoUpload(e.target.files[0])} className="mt-1 p-2 border rounded" />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600">Upload Documents:</label>
        <input type="file" multiple onChange={(e) => handleDocumentUpload(e.target.files)} className="mt-1 p-2 border rounded" />
      </div>
      <button onClick={handleSubmit} className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">Submit</button>
    </div>
  );
};

export default AddStudent;
