import React, { useState, useEffect } from 'react';
import AddNewGroupDialog from './AddNewGroupDialog';
import NewExamDialog from './NewExamDialog';
import AddSubjectDialog from './AddSubjectDialog';
import SubjectDetails from './SubjectDetails';

const ExamGroupManager = () => {
  const [examGroups, setExamGroups] = useState([]);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);
  const [isAddNewGroupDialogOpen, setAddNewGroupDialogOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newExamType, setNewExamType] = useState('');
  const [newExamTitle, setNewExamTitle] = useState('');
  const [newExamSession, setNewExamSession] = useState('');
  const [isAddSubjectDialogOpen, setAddSubjectDialogOpen] = useState(false);
  const [selectedExamIndex, setSelectedExamIndex] = useState(null);

  const handleAddNewExamGroup = () => {
    setAddNewGroupDialogOpen(true);
  };

  const handleSaveNewExamGroup = () => {
    if (newGroupName.trim() === '' || newExamType.trim() === '') {
      alert('Please fill out all fields.');
      return;
    }

    const newExamGroup = {
      id: examGroups.length + 1,
      groupName: newGroupName,
      examType: newExamType,
      exams: [],
    };

    setExamGroups([...examGroups, newExamGroup]);
    setAddNewGroupDialogOpen(false);
    setNewGroupName('');
    setNewExamType('');
  };

  const handleAddNewExam = (groupIndex) => {
    setSelectedGroupIndex(groupIndex);
  };

  const handleSaveNewExam = () => {
    if (newExamTitle.trim() === '' || newExamSession.trim() === '') {
      alert('Please fill out all fields.');
      return;
    }

    const updatedGroups = [...examGroups];
    const newExam = {
      title: newExamTitle,
      session: newExamSession,
      subjects: [],
    };

    if (selectedGroupIndex !== null && selectedGroupIndex < updatedGroups.length) {
      console.log("sdfdsffsfdfsfsf "+selectedGroupIndex);
      updatedGroups[selectedGroupIndex].exams.push(newExam);
      setExamGroups(updatedGroups);
    }

    setSelectedGroupIndex(null);
    setNewExamTitle('');
    setNewExamSession('');
    
  };

  const handleAddSubject = (groupIndex, examIndex) => {
    setSelectedGroupIndex(groupIndex);
    setSelectedExamIndex(examIndex);
    setAddSubjectDialogOpen(true);
  };

  const handleSaveSubject = (subjectData) => {
    console.log('selectedGroupIndex:', selectedGroupIndex); // Log the selectedGroupIndex
    console.log('selectedExamIndex:', selectedExamIndex); // Log the selectedExamIndex
  
    if (selectedGroupIndex !== null && selectedGroupIndex < examGroups.length) {
      const updatedGroups = [...examGroups];
      const selectedExams = updatedGroups[selectedGroupIndex].exams;
  
      if (selectedExamIndex !== null && selectedExamIndex < selectedExams.length) {
        // If subjects array doesn't exist, initialize it
        if (!selectedExams[selectedExamIndex].subjects) {
          selectedExams[selectedExamIndex].subjects = [];
        }
  
        selectedExams[selectedExamIndex].subjects.push(subjectData);
        setExamGroups(updatedGroups);
      }
    }
  
    setAddSubjectDialogOpen(false);
  };
  

  const handleCancel = () => {
    setSelectedGroupIndex(null);
    setAddNewGroupDialogOpen(false);
    setNewGroupName('');
    setNewExamType('');
    setNewExamTitle('');
    setNewExamSession('');
    setAddSubjectDialogOpen(false);
  };

  useEffect(() => {
    setExamGroups([
      {
        id: 1,
        groupName: 'Group 1',
        examType: 'GPA grading system',
        exams: [
          {
            title: 'Exam 1',
            session: 'Session 1',
            subjects: [
              {
                subject: 'English',
                date: '2024-01-01',
                time: '09:00',
                duration: 90,
                marksMax: 100,
                marksMin: 40,
              },
            ],
          },
        ],
      },
    ]);
  }, []);

  return (
    <div className="container mx-auto my-8">
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
        onClick={handleAddNewExamGroup}
      >
        Add New Exam Group
      </button>

      {isAddNewGroupDialogOpen && (
        <AddNewGroupDialog
          newGroupName={newGroupName}
          newExamType={newExamType}
          setNewGroupName={setNewGroupName}
          setNewExamType={setNewExamType}
          onSave={handleSaveNewExamGroup}
          onCancel={handleCancel}
        />
      )}

      {examGroups.map((group, index) => (
        <React.Fragment key={index}>
          <table className="border-collapse w-full mt-4">
            <thead>
              <tr>
                <th className="border p-2">Group Name</th>
                <th className="border p-2">Exam Type</th>
                <th className="border p-2">Exams</th>
                <th className="border p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr className={index % 2 === 0 ? 'bg-white' : 'bg-white'}>
                <td className="border p-2 font-semibold">{group.groupName}</td>
                <td className="border p-2 font-semibold ">{group.examType}</td>
                <td className="border p-2">
                  <table className="border-collapse w-full">
                    <thead>
                      <tr>
                        <th className="border p-2">Exam Title</th>
                        <th className="border p-2">Session</th>
                        <th className="border p-2">Subjects</th>
                      </tr>
                    </thead>
                    <tbody>
                      {group.exams.map((exam, examIndex) => (
                        <React.Fragment key={examIndex}>
                          <tr className={examIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                            <td className="border p-2">{exam.title}</td>
                            <td className="border p-2">{exam.session}</td>
                            <td className="border p-2">
                              {isAddSubjectDialogOpen && selectedExamIndex === examIndex && (
                                <AddSubjectDialog
                                  onSave={handleSaveSubject}
                                  onCancel={() => setAddSubjectDialogOpen(false)}
                                />
                              )}
                              {!isAddSubjectDialogOpen && (
                                <SubjectDetails subjects={exam.subjects} />
                              )}
                              <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
                                onClick={() => handleAddSubject(index,examIndex)}
                              >
                                Add Subject
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </td>
                <td className="border p-2">
                  <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out mr-2"
                    onClick={() => handleAddNewExam(index)}
                  >
                    New Exam
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      ))}

      {selectedGroupIndex !== null && (
        <NewExamDialog
          newExamTitle={newExamTitle}
          newExamSession={newExamSession}
          setNewExamTitle={setNewExamTitle}
          setNewExamSession={setNewExamSession}
          onSave={handleSaveNewExam}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default ExamGroupManager;
