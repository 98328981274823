import React, { useState } from "react";

function TimetableEditor() {
  const getWeekdays = () => ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const getTeachers = () => [
    "Shyam Singh",
    "Shubham Singh",
    "Rajesh Kumar",
    "Suman Verma",
  ];
  const getSubjects = () => ["English", "Hindi", "Math", "Science", "History"];
  const getClassNames = () => ["First-A", "First-B", "Second-A", "Second-B"];

  const getEmptyTimetable = () => [
    {
      day: getWeekdays()[0],
      details: [{ startTime: "", endTime: "", subject: "", teacher: "" }],
    },
  ];

  const [selectedClass, setSelectedClass] = useState(getClassNames()[0]);
  const [timetable, setTimetable] = useState(getEmptyTimetable());

  const handleClassChange = (event) => {
    const selectedClassName = event.target.value;
    setSelectedClass(selectedClassName);
  };

  const handleTeacherChange = (day, rowIndex, selectedTeacher) => {
    setTimetable((prevTimetable) => {
      const newTimetable = [...prevTimetable];
      newTimetable[rowIndex].details[0].teacher = selectedTeacher;
      return newTimetable;
    });
  };

  const handleSubjectChange = (day, rowIndex, selectedSubject) => {
    setTimetable((prevTimetable) => {
      const newTimetable = [...prevTimetable];
      newTimetable[rowIndex].details[0] = {
        ...newTimetable[rowIndex].details[0],
        subject: selectedSubject,
      };
      return newTimetable;
    });
  };
  

  const handleStartTimeChange = (day, rowIndex, newStartTime) => {
    setTimetable((prevTimetable) => {
      const newTimetable = [...prevTimetable];
      newTimetable[rowIndex].details[0].startTime = newStartTime;
      return newTimetable;
    });
  };

  const handleEndTimeChange = (day, rowIndex, newEndTime) => {
    setTimetable((prevTimetable) => {
      const newTimetable = [...prevTimetable];
      newTimetable[rowIndex].details[0].endTime = newEndTime;
      return newTimetable;
    });
  };

  const handleAddRow = () => {
    setTimetable((prevTimetable) => [
      ...prevTimetable,
      {
        day: getWeekdays()[0],
        details: [{ startTime: "", endTime: "", subject: "", teacher: "" }],
      },
    ]);
  };

  const handleRemoveRow = () => {
    if (timetable.length > 1) {
      setTimetable((prevTimetable) => prevTimetable.slice(0, -1));
    }
  };

  const handleSave = () => {
    const savedData = {
      id: 1,
      clsName: selectedClass,
      details: timetable.flatMap((entry) =>
        entry.details.map((detail) => ({
          day: entry.day,
          startTime: detail.startTime,
          endTime: detail.endTime,
          subject: detail.subject,
          teacher: detail.teacher,
        }))
      ),
    };

    console.log(savedData);
  };

  return (
    <div className="container mx-auto md:mt-8">
      <h1 className="text-2xl md:font-bold md:mb-3 mt-1 font-bold md:text-left text-center">
        Timetable Editor
      </h1>

      <label className="text-lg font-semibold mb-2 block">Select Class:</label>
      <select
        className="border p-2 mb-4 w-full"
        onChange={handleClassChange}
        value={selectedClass}
      >
        {getClassNames().map((className) => (
          <option key={className} value={className}>
            {className}
          </option>
        ))}
      </select>

      <div className="overflow-auto">
        <h2 className="text-2xl font-bold mb-4 text-center">{selectedClass}</h2>

        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2"></th>
              {getWeekdays().map((day) => (
                <th
                  key={day}
                  className="border border-gray-300 p-2 bg-gray-100"
                >
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {timetable.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <tr>
                  <td className="border border-gray-300 p-2 w-1/6">
                    <div>
                      <label>Start Time:</label>
                      <input
                        type="time"
                        value={row.details[0].startTime}
                        onChange={(e) =>
                          handleStartTimeChange(
                            row.day,
                            rowIndex,
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div>
                      <label>End Time:</label>
                      <input
                        type="time"
                        value={row.details[0].endTime}
                        onChange={(e) =>
                          handleEndTimeChange(row.day, rowIndex, e.target.value)
                        }
                      />
                    </div>
                  </td>
                  {getWeekdays().map((day, dayIndex) => (
                    <td
                      key={day}
                      className="border border-gray-300 p-2 bg-gray-50 cursor-pointer"
                    >
                      <select
                        value={row.details[0].teacher}
                        onChange={(e) =>
                          handleTeacherChange(row.day, rowIndex, e.target.value)
                        }
                      >
                        <option value="" disabled>
                          Select Teacher
                        </option>
                        {getTeachers().map((teacher) => (
                          <option key={teacher} value={teacher}>
                            {teacher}
                          </option>
                        ))}
                      </select>
                      <br />
                      <select
                        value={row.details[0].subject}
                        onChange={(e) =>
                          handleSubjectChange(row.day, rowIndex, e.target.value)
                        }
                      >
                        <option value="" disabled>
                          Select Subject
                        </option>
                        {getSubjects().map((subject) => (
                          <option key={subject} value={subject}>
                            {subject}
                          </option>
                        ))}
                      </select>
                    </td>
                  ))}
                </tr>
                {rowIndex === timetable.length - 1 && (
                  <tr>
                    <td
                      colSpan={getWeekdays().length + 1}
                      className="border border-gray-300 p-2 bg-gray-50 flex justify-end"
                    >
                      <button
                        onClick={handleAddRow}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                      >
                        +
                      </button>
                      <button
                        onClick={handleRemoveRow}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
                      >
                        -
                      </button>
                      <button
                        onClick={handleSave}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Save
                      </button>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TimetableEditor;
