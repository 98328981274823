import React, { useState } from 'react';
import Logo from './siteiconMain.png'; // Adjust the path accordingly

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    // Add your authentication logic here
    console.log(`Logging in with username: ${username}, password: ${password}`);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-500 via-blue-500 to-green-500">
      <div className="bg-white p-8 rounded-md shadow-md w-full max-w-md">
        {/* Logo */}
        <div className="mb-6 text-center">
          <img src={Logo} alt="Logo" className="mx-auto h-16 rounded-full" />
        </div>
        {/* Login Form */}
        <h2 className="text-3xl font-extrabold text-center text-gray-800 mb-6">Welcome to School</h2>
        <div className="mb-4">
          <label htmlFor="username" className="block text-gray-700 font-semibold mb-2">
            Username
          </label>
          <input
            type="text"
            id="username"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-purple-500 placeholder-gray-500"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block text-gray-700 font-semibold mb-2">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-purple-500 placeholder-gray-500"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          className="bg-gradient-to-r from-purple-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out w-full"
          onClick={handleLogin}
        >
          Log In
        </button>
      </div>
    </div>
  );
};

export default Login;
