import React, { useState } from 'react';

const AddNewUser = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    education: '',
    photo: null,
    govDocuments: null,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., API request to save user data
    console.log('Form submitted:', formData);
  };

  return (
    <>
    <h2 className="text-3xl font-semibold m-4 text-center">Add New User</h2>
    
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
      
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Personal Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <label>
            <span className="text-gray-700">First Name:</span>
            <input
              type="text"
              name="firstName"
              onChange={handleChange}
              className="border p-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </label>
          <label>
            <span className="text-gray-700">Last Name:</span>
            <input
              type="text"
              name="lastName"
              onChange={handleChange}
              className="border p-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </label>
        </div>

        {/* Educational Details */}
        <label className="flex flex-col space-y-2">
          <span className="text-gray-700">Education:</span>
          <input
            type="text"
            name="education"
            onChange={handleChange}
            className="border p-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </label>

        {/* Photo Upload */}
        <label className="flex flex-col space-y-2">
          <span className="text-gray-700">Upload Photo:</span>
          <input
            type="file"
            name="photo"
            onChange={handleFileChange}
            className="border p-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </label>

        {/* Government Documents Upload */}
        <label className="flex flex-col space-y-2">
          <span className="text-gray-700">Upload Gov Documents:</span>
          <input
            type="file"
            name="govDocuments"
            onChange={handleFileChange}
            className="border p-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </label>

        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300"
        >
          Submit
        </button>
      </form>
    </div>
    </>
  );
};

export default AddNewUser;
