import React from 'react'

import Header from "../header/Header";
import SideNav from "../sidenav/SideNav";
import Footer from '../footer/Footer';
function Main({children}) {
  return (
    <div className="flex h-screen">
      <SideNav />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-x-hidden overflow-y-auto  p-4  bg-gradient-to-r from-purple-500 via-blue-500 to-green-500">
          {children}
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default Main