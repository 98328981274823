import React, { useState } from 'react';

const Vendors = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddVendorDialog, setShowAddVendorDialog] = useState(false);
  const [vendors, setVendors] = useState([
    {
      id: 1,
      vendorName: 'Vendor 1',
      contactNo: '1234567890',
      address: '123 Street, City',
      mobileNo: '9876543210',
      gstNo: 'GST123',
    },
    {
      id: 2,
      vendorName: 'Vendor 2',
      contactNo: '9876543210',
      address: '456 Street, City',
      mobileNo: '1234567890',
      gstNo: 'GST456',
    },
    // Add more dummy vendors as needed
  ]);
  const [newVendor, setNewVendor] = useState({
    id: '',
    vendorName: '',
    contactNo: '',
    address: '',
    mobileNo: '',
    gstNo: '',
  });
  const [editingVendor, setEditingVendor] = useState(null);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAddVendor = () => {
    setEditingVendor(null);
    setShowAddVendorDialog(true);
  };

  const handleEditVendor = (vendor) => {
    setEditingVendor(vendor);
    setShowAddVendorDialog(true);
  };

  const handleFormChange = (event, field) => {
    setNewVendor((prevVendor) => ({
      ...prevVendor,
      [field]: event.target.value,
    }));
  };

  const handleSaveVendor = (vendor) => {
    setVendors((prevVendors) => [...prevVendors, vendor]);
    console.log('New Vendor:', vendor);
    setShowAddVendorDialog(false);
    setNewVendor({
      id: '',
      vendorName: '',
      contactNo: '',
      address: '',
      mobileNo: '',
      gstNo: '',
    });
  };

  const handleEditSaveVendor = (editedVendor) => {
    setVendors((prevVendors) =>
      prevVendors.map((vendor) => (vendor.id === editedVendor.id ? editedVendor : vendor))
    );
    console.log('Edited Vendor:', editedVendor);
    setShowAddVendorDialog(false);
    setEditingVendor(null);
  };

  const filteredVendors = vendors.filter(
    (vendor) =>
      vendor.vendorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vendor.contactNo.includes(searchTerm) ||
      vendor.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vendor.mobileNo.includes(searchTerm) ||
      vendor.gstNo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto mt-8 p-4">
      <h1 className="text-3xl font-bold mb-4">Vendor Management</h1>

      {/* Global Search Input */}
      <input
        type="text"
        placeholder="Search vendors..."
        value={searchTerm}
        onChange={handleSearch}
        className="mb-4 p-2 border border-gray-300 rounded-md w-full"
      />

      {/* Add New Vendor Button */}
      <button onClick={handleAddVendor} className="mb-4 bg-blue-500 text-white p-2 rounded-md">
        Add New Vendor
      </button>

      {/* Vendor Table */}
      <table className="w-full border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 p-2">ID</th>
            <th className="border border-gray-300 p-2">Vendor Name</th>
            <th className="border border-gray-300 p-2">Contact No</th>
            <th className="border border-gray-300 p-2">Address</th>
            <th className="border border-gray-300 p-2">Mobile No</th>
            <th className="border border-gray-300 p-2">GST No</th>
            <th className="border border-gray-300 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredVendors.map((vendor) => (
            <tr key={vendor.id} className="border border-gray-300">
              <td className="border border-gray-300 p-2">{vendor.id}</td>
              <td className="border border-gray-300 p-2">{vendor.vendorName}</td>
              <td className="border border-gray-300 p-2">{vendor.contactNo}</td>
              <td className="border border-gray-300 p-2">{vendor.address}</td>
              <td className="border border-gray-300 p-2">{vendor.mobileNo}</td>
              <td className="border border-gray-300 p-2">{vendor.gstNo}</td>
              <td className="border border-gray-300 p-2">
                <button
                  onClick={() => handleEditVendor(vendor)}
                  className="bg-yellow-500 text-white p-2 rounded-md"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Add/Edit Vendor Dialog */}
      {showAddVendorDialog && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-md">
            <h2 className="text-xl font-bold mb-4">
              {editingVendor ? 'Edit Vendor' : 'Add New Vendor'}
            </h2>
            <form>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">ID:</label>
                <input
                  type="text"
                  value={editingVendor ? editingVendor.id : newVendor.id}
                  onChange={(e) => handleFormChange(e, 'id')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Vendor Name:</label>
                <input
                  type="text"
                  value={editingVendor ? editingVendor.vendorName : newVendor.vendorName}
                  onChange={(e) => handleFormChange(e, 'vendorName')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Contact No:</label>
                <input
                  type="text"
                  value={editingVendor ? editingVendor.contactNo : newVendor.contactNo}
                  onChange={(e) => handleFormChange(e, 'contactNo')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Address:</label>
                <input
                  type="text"
                  value={editingVendor ? editingVendor.address : newVendor.address}
                  onChange={(e) => handleFormChange(e, 'address')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Mobile No:</label>
                <input
                  type="text"
                  value={editingVendor ? editingVendor.mobileNo : newVendor.mobileNo}
                  onChange={(e) => handleFormChange(e, 'mobileNo')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">GST No:</label>
                <input
                  type="text"
                  value={editingVendor ? editingVendor.gstNo : newVendor.gstNo}
                  onChange={(e) => handleFormChange(e, 'gstNo')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
            </form>
            <button
              onClick={() => {
                editingVendor ? handleEditSaveVendor(editingVendor) : handleSaveVendor(newVendor);
              }}
              className="bg-blue-500 text-white p-2 rounded-md mr-2"
            >
              Save
            </button>
            <button
              onClick={() => {
                setEditingVendor(null);
                setShowAddVendorDialog(false);
              }}
              className="bg-gray-500 text-white p-2 rounded-md"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Vendors;
