import React, { useState } from 'react';

const MultiSelect = ({ options, selected, onSelect, label }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onSelect(option);
  };

  const handleClearSelection = () => {
    onSelect(null);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block">
      <button
        type="button"
        className="bg-blue-500 text-white py-2 px-4 rounded-md focus:outline-none focus:ring focus:border-blue-300"
        onClick={toggleDropdown}
      >
        {label}
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-2 bg-white rounded-md shadow-lg">
          <ul>
            {options.map((option) => (
              <li
                key={option}
                className={`${
                  selected === option ? 'bg-blue-500 text-white' : ''
                } cursor-pointer py-2 px-4`}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
          <button
            type="button"
            className="mt-2 px-4 py-2 bg-gray-300 rounded-md"
            onClick={handleClearSelection}
          >
            Clear Selection
          </button>
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
