import React from 'react';

const AddNewGroupDialog = ({ onSave, onCancel, newGroupName, setNewGroupName, newExamType, setNewExamType }) => {
  return (
    <div className="bg-white p-8 rounded-md">
      <label htmlFor="groupName" className="block text-gray-700 font-semibold mb-2">
        Group Name:
      </label>
      <input
        type="text"
        id="groupName"
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
        value={newGroupName}
        onChange={(e) => setNewGroupName(e.target.value)}
      />

      <label htmlFor="examType" className="block text-gray-700 font-semibold mb-2">
        Exam Type:
      </label>
      <select
        id="examType"
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
        value={newExamType}
        onChange={(e) => setNewExamType(e.target.value)}
      >
        <option value="">Select Exam Type</option>
        <option value="GPA grading system">GPA grading system</option>
        <option value="General Purpose (pass/fail)">General Purpose (pass/fail)</option>
        <option value="School base grading system">School base grading system</option>
      </select>

      <div className="flex justify-between">
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
          onClick={onSave}
        >
          Save
        </button>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddNewGroupDialog;
