

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function SideNav() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [openInnerOptions, setOpenInnerOptions] = useState('');

  const toggleInnerOptions = (label) => {
    setOpenInnerOptions((prevLabel) => (prevLabel === label ? '' : label));
  };

  const navigationItems = [
    { path: '/', label: 'Home' },
    { path: '/profile', label: 'Profile' },
    { path: '/team', label: 'Team' },
    {
      label: 'User',
      innerOptions: [
        { path: '/user/add', label: 'AddNewUser' },
        { path: '/user/edit', label: 'EditUser' },
        { path: '/user/all', label: 'AllUsers' },
      ],
    },
    {
      label: 'Attendance',
      innerOptions: [
        { path: '/attendance/my', label: 'My Attendance' },
        { path: '/attendance/team', label: 'Team Attendance' },
        { path: '/attendance/student/tracker', label: 'Track Student Attendance' },
        { path: '/attendance/student/view', label: 'View Student Attendance' },
        
      ],
    },
    {
      label: 'Notice Board',
      innerOptions: [
        { path: '/notice/manage', label: 'Manage Notice' },
        { path: '/notice/view', label: 'View Notice' },
      ],
    },
    {
      label: 'TimeTable',
      innerOptions: [
        { path: '/timetable/editor', label: 'Manage TimeTable' },
        { path: '/timetable/view', label: 'View TimeTable' },
      ],
    },
    {
      label: 'Student',
      innerOptions: [
        { path: '/student/add', label: 'Add Student' },
        { path: '/student/view', label: 'View Student' },
      ],
    },
    {
      label: 'Class',
      innerOptions: [
        { path: '/class/add', label: 'Add New Class' },
        { path: '/class/view', label: 'View Classes' },
      ],
    },
    {
      label: 'Subjects',
      innerOptions: [
        { path: '/subject/add', label: 'Add New Subject' },
        { path: '/subject/view', label: 'View Subject' },
      ],
    },
    {
      label: 'Account',
      innerOptions: [
        { path: '/account/payfee', label: 'Pay Fee' },
        { path: '/account/salary-calculator', label: 'Salary Calculator' },
        { path: '/account/fee-group/add', label: 'Add New Fee Group' },
        { path: '/account/fee-group/view', label: 'View Fee Groups' },
        { path: '/account/my-salary-slip', label: 'Salary Slip' },
      ],
    },{
      label: 'Inventory',
      innerOptions: [
        { path: '/inventory/vendors', label: 'Manage Vendors' },
        { path: '/inventory/products', label: 'Manage Product' },
        { path: '/inventory/purchased-stock-entry', label: 'Purchased Stock Entry' },
        { path: '/inventory/purchased-stock-records', label: 'Purchased Stock Records' },
        { path: '/inventory/sale-stock-entry', label: 'Sales Stock Entry' },
        { path: '/inventory/sale-stock-records', label: 'Sales Stock Records' },
        { path: '/inventory/stocks', label: 'Stocks' },
      ],
    },
    {
      label: 'Exam',
      innerOptions: [
        { path: '/exam/exam-group-manager', label: 'Exam Group Manager' },
        { path: '/exam/time-table', label: 'Exam Time Table' },
        { path: '/exam/add-marks', label: 'Add Marks' },
        { path: '/exam/admit-card', label: 'Admit Card' },
        { path: '/exam/marksheet', label: 'Marksheet' },
      ]
    }
  ];

  return (
    <aside
      className={`bg-gray-800 text-white p-4 transition-all duration-1000 ${
        isOpen ? 'lg:w-64 md:w-64 w-full' : 'md:w-16 w-auto'
      }`}
    >
      <button onClick={toggleSidebar} className="text-white float-right">
        {isOpen ? 'X' : '-->'}
      </button>
      {isOpen && (
        <div>
          <nav className="flex flex-col items-center ">
            <div className="mb-5 ">
              <img
                src="https://media.istockphoto.com/id/1189072996/vector/young-woman-goes-through-her-datebook-in-the-morning-and-plans-her-day.jpg?s=2048x2048&w=is&k=20&c=LONx2BxY_gS80rWhg6TPy1kVxUdWGxbZsb0qqTE8xYU="
                alt="Profile"
                className="rounded-full w-24 h-24 "
              />
              <div className="font-bold text-xl">Shubham Singh</div>
            </div>
            <hr className="w-full"></hr>
            <ul className="mt-4">
              {navigationItems.map((item, index) => (
                <li key={index}>
                  {item.innerOptions ? (
                    <span
                      onClick={() => toggleInnerOptions(item.label)}
                      className="cursor-pointer text-white"
                    >
                      {item.label} {openInnerOptions === item.label ? '▲' : '▼'}
                    </span>
                  ) : (
                    <Link to={item.path} className="text-white hover:underline">
                      {item.label}
                    </Link>
                  )}
                  {item.innerOptions && openInnerOptions === item.label && (
                    <ul className="pl-4">
                      {item.innerOptions.map((innerItem, innerIndex) => (
                        <li key={innerIndex}>
                          <Link
                            to={innerItem.path}
                            className="text-white hover:underline"
                          >
                            {innerItem.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}
    </aside>
  );
}

export default SideNav;