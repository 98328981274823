import React, { useState } from "react";
import ProfileView from "./ProfileView";
import ProfileEdit from "./ProfileEdit";

const Profile = () => {
  const initialProfile = {
    name: "John Doe",
    email: "john@example.com",
    avatar:
      "https://media.istockphoto.com/id/1189072996/vector/young-woman-goes-through-her-datebook-in-the-morning-and-plans-her-day.jpg?s=2048x2048&w=is&k=20&c=LONx2BxY_gS80rWhg6TPy1kVxUdWGxbZsb0qqTE8xYU=",
    fatherName: "Joshef Doe",
    motherName: "John's Mom",
    // Add more profile information here
  };

  const [editMode, setEditMode] = useState(false);
  const [profile, setProfile] = useState(initialProfile);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = (updatedProfile) => {
    // You can save the updated profile to the backend or update the state as needed
    setProfile(updatedProfile);
    setEditMode(false);
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-white rounded shadow-md max-w-md">
      {editMode ? (
        <ProfileEdit profile={profile} onSave={handleSave} />
      ) : (
        <ProfileView profile={profile} />
      )}
      {!editMode && (
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 transition duration-300 ease-in-out"
          onClick={handleEdit}
        >
          Edit Profile
        </button>
      )}
    </div>
  );
};

export default Profile;
