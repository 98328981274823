// // src/components/TeamAttendancePage.js

// import React, { useState } from 'react';
// import TeamMember from '../team/TeamMember';


// const dummyTeamAttendance = [
//   {
//     id: 1,
//     name: 'John Doe',
//     photo: 'https://placekitten.com/300/300',
//     attendance: [
//       { date: '2024-01-01', status: 'present' },
//       { date: '2024-01-02', status: 'absent' },
//       { date: '2024-01-03', status: 'present' },
//       // Add more dates as needed
//     ],
//   },
//   {
//     id: 2,
//     name: 'Jane Smith',
//     photo: 'https://placekitten.com/301/301',
//     attendance: [
//       { date: '2024-01-01', status: 'absent' },
//       { date: '2024-01-02', status: 'present' },
//       { date: '2024-01-03', status: 'absent' },
//       // Add more dates as needed
//     ],
//   },
//   // Add more team members as needed
// ];

// const TeamAttendancePage = () => {
//   const [selectedAttendance, setSelectedAttendance] = useState(null);

//   const handleApprove = (date, memberId) => {
//     // Add logic to update the attendance approval status
//     console.log(`Approving attendance for ${date} of team member ${memberId}`);
//   };

//   const renderTableHeader = () => {
//     const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

//     return (
//       <>
//         <th key="photo">Photo</th>
//         {daysOfWeek.map((day) => (
//           <th key={day}>{day}</th>
//         ))}
//       </>
//     );
//   };

//   const renderTableBody = () => {
//     return dummyTeamAttendance.map((member) => (
//       <tr key={member.id}>
//         <td>
//           <TeamMember name={member.name} photo={member.photo} />
//         </td>
//         {member.attendance.map((dayAttendance) => (
//           <td key={dayAttendance.date} className={dayAttendance.status === 'pending' ? 'bg-yellow-200' : ''}>
//             {dayAttendance.status === 'pending' ? (
//               <>
//                 <span>Not Approved</span>
//                 <button
//                   className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
//                   onClick={() => {
//                     setSelectedAttendance({ memberId: member.id, date: dayAttendance.date });
//                   }}
//                 >
//                   Approve
//                 </button>
//               </>
//             ) : (
//               <span className="text-green-500">Approved</span>
//             )}
//           </td>
//         ))}
//       </tr>
//     ));
//   };

//   return (
//     <div className="bg-gray-100 min-h-screen p-4">
//       <h1 className="text-2xl font-semibold mb-4">Team Attendance</h1>
//       <div className="overflow-x-auto">
//         <table className="table-auto w-full">
//           <thead>
//             <tr>{renderTableHeader()}</tr>
//           </thead>
//           <tbody>{renderTableBody()}</tbody>
//         </table>
//       </div>
//       {selectedAttendance && (
//         <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
//           <div className="bg-white p-4 rounded shadow-md">
//             <p className="mb-2">Approve attendance for {selectedAttendance.date}?</p>
//             <button
//               className="bg-blue-500 text-white px-4 py-2 rounded"
//               onClick={() => {
//                 handleApprove(selectedAttendance.date, selectedAttendance.memberId);
//                 setSelectedAttendance(null);
//               }}
//             >
//               Approve
//             </button>
//             <button
//               className="ml-2 bg-gray-300 px-4 py-2 rounded"
//               onClick={() => {
//                 setSelectedAttendance(null);
//               }}
//             >
//               Cancel
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TeamAttendancePage;




// src/components/TeamAttendancePage.js



// src/TeamAttendancePage.js
import React from 'react';
import TeamAttendanceTable from './TeamAttendanceTable';

const TeamAttendancePage = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Team Attendance</h1>
      <TeamAttendanceTable />
    </div>
  );
};

export default TeamAttendancePage;
