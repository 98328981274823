import React, { useState } from 'react';
import MultiSelect from './MultiSelect';

const Marksheet = () => {
  const [selectedGroup, setSelectedGroup] = useState(null);

  const data = [
    {
      id: 1,
      groupName: "Group 1",
      examType: "GPA grading system",
      classData: [
        {
          className: "First",
          section: "A",
          students: [
            {
              id: 1,
              name: "Sam",
              fatherName: "Joy",
              motherName: "Natasha",
              rollNo: 101,
              dob: "11/10/2001",
              photo: "https://cdn.pixabay.com/photo/2024/01/15/19/40/animal-8510775_1280.jpg", // Replace with the actual photo URL
            },
            {
              id: 2,
              name: "Sam2",
              fatherName: "Joy2",
              motherName: "Natasha2",
              rollNo: 102,
              dob: "11/10/2001",
              photo: "https://cdn.pixabay.com/photo/2014/11/30/14/11/cat-551554_1280.jpg", // Replace with the actual photo URL
            },
          ]
        },
      ],
      exams: [
        {
          id: 1,
          title: "Exam 1",
          session: "Session 1",
          subjects: [
            {
              id: 1,
              name: "hindi",
              date: "03/05/2024",
              time: "20:40",
              duration: 120,
              MaxMarks: 200,
              MinMarks: 60,
              marks: [
                { rollNo: 101, obtainMarks: 35 },
                { rollNo: 102, obtainMarks: 45 },
              ],
            },
            {
              id: 2,
              name: "hindi2",
              date: "03/05/2024",
              time: "20:40",
              duration: 120,
              MaxMarks: 200,
              MinMarks: 60,
              marks: [
                { rollNo: 101, obtainMarks: 35 },
                { rollNo: 102, obtainMarks: 45 },
              ],
            },
          ]
        },
        {
          id: 2,
          title: "Exam 2",
          session: "Session 1",
          subjects: [
            {
              id: 1,
              name: "hindi",
              date: "03/08/2024",
              time: "20:40",
              duration: 120,
              MaxMarks: 200,
              MinMarks: 60,
              marks: [
                { rollNo: 101, obtainMarks: 35 },
                { rollNo: 102, obtainMarks: 45 },
              ],
            },
            {
              id: 2,
              name: "hindi2",
              date: "03/08/2024",
              time: "20:40",
              duration: 120,
              MaxMarks: 200,
              MinMarks: 60,
              marks: [
                { rollNo: 101, obtainMarks: 35 },
                { rollNo: 102, obtainMarks: 45 },
              ],
            },
          ]
        }
      ],
    },
  ];


  const filteredData = data.filter(group => !selectedGroup || group.groupName === selectedGroup);

  const calculateTotalMarks = (marks, key) => {
    return marks.reduce((total, mark) => total + mark[key], 0);
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <MultiSelect
          options={data.map(group => group.groupName)}
          selected={selectedGroup}
          onSelect={setSelectedGroup}
          label="Group"
        />
      </div>

      <div>
        {filteredData.map(group =>
          group.classData.map(classData =>
            classData.students.map(student => (
              <div key={`${group.id}-${classData.className}-${classData.section}-${student.id}`} className="mb-8 border p-4">
                {/* Section 1: School Details and Student Details */}
                <div className="flex items-center justify-center mb-4">
                  <div className="mr-4">
                    {/* School Logo (Replace with actual logo URL) */}
                    <img src="https://cdn.pixabay.com/photo/2024/01/15/19/40/animal-8510775_1280.jpg" alt="School Logo" className="h-12 w-12 rounded-full" />
                  </div>
                  <div>
                    <p className="font-bold text-xl">{group.groupName} Schoo </p>
                  </div>
                </div>

                {/* Section 2: Student Details */}
                <div className="flex">
                  <div className="mr-4">
                    {/* Student Photo (Replace with actual photo URL) */}
                    <img src={student.photo} alt={`Student ${student.rollNo}`} className="h-24 w-24 rounded-full" />
                  </div>
                  <div>
                    <p className="font-bold">{student.name}</p>
                    <p>Father's Name: {student.fatherName}</p>
                    <p>Mother's Name: {student.motherName}</p>
                  </div>
                  <div className="ml-auto">
                    <p className="font-bold">Session: {group.exams[0].session}</p>
                    <p>Roll No: {student.rollNo}</p>
                    <p>Class: {classData.className}</p>
                    <p>Section: {classData.section}</p>
                  </div>
                </div>

                {/* Section 3: Subject and Exam Details */}
                <div className="mt-8">
                  <table className="w-full border-collapse border border-gray-500">
                    <thead>
                      <tr>
                        <th className="p-2 border-l border-r">Subjects</th>
                        {group.exams.map(exam => (
                          <React.Fragment key={exam.id}>
                            <th colSpan="3" className="p-2 border-r">{exam.title}</th>
                          </React.Fragment>
                        ))}
                      </tr>
                      <tr>
                        <th className="p-2 border-l border-r"></th>
                        {group.exams.map(exam => (
                          <React.Fragment key={exam.id}>
                            <th className="p-2 border-r">Max Marks</th>
                            <th className="p-2 border-r">Min Marks</th>
                            <th className="p-2 border-r">Obtain Marks</th>
                          </React.Fragment>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {group.exams[0].subjects.map(subject => (
                        <tr key={subject.id}>
                          <td className="p-2 border-l border-r">{subject.name}</td>
                          {group.exams.map(exam => {
                            const examSubject = exam.subjects.find(examSub => examSub.id === subject.id);
                            const studentMarks = examSubject?.marks.find(mark => mark.rollNo === student.rollNo);
                            return (
                              <React.Fragment key={`${subject.id}-${exam.id}-${student.id}`}>
                                <td className="p-2 border-r">{examSubject?.MaxMarks}</td>
                                <td className="p-2 border-r">{examSubject?.MinMarks}</td>
                                <td className="p-2 border-r">{studentMarks ? studentMarks.obtainMarks : 'N/A'}</td>
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      ))}
                      <tr>
                        <td className="p-2 border-l border-r">Total</td>
                        {group.exams.map(exam => (
                          <React.Fragment key={`total-${exam.id}`}>
                            <td className="p-2 border-r">{calculateTotalMarks(group.exams.flatMap(exam => exam.subjects).flatMap(subject => subject.marks), 'MaxMarks')}</td>
                            <td className="p-2 border-r">{calculateTotalMarks(group.exams.flatMap(exam => exam.subjects).flatMap(subject => subject.marks), 'obtainMarks')}</td>
                          </React.Fragment>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))
          )
        )}
      </div>
    </div>
  );
};

export default Marksheet;