import React, { useState } from 'react';

const NewExamDialog = ({ onSave, onCancel, newExamTitle, setNewExamTitle, newExamSession, setNewExamSession }) => {
  const handleSave = () => {
    if (newExamTitle.trim() === '' || newExamSession.trim() === '') {
      alert('Please fill out all fields.');
      return;
    }

    onSave();
  };

  return (
    <div className="bg-white p-8 rounded-md">
      <label htmlFor="newExamTitle" className="block text-gray-700 font-semibold mb-2">
        Exam Title:
      </label>
      <input
        type="text"
        id="newExamTitle"
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
        value={newExamTitle}
        onChange={(e) => setNewExamTitle(e.target.value)}
      />

      <label htmlFor="newExamSession" className="block text-gray-700 font-semibold mb-2">
        Session:
      </label>
      <select
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
        value={newExamSession}
        onChange={(e) => setNewExamSession(e.target.value)}
      >
        <option value="">Select Session</option>
        <option value="Session1">Session1</option>
        <option value="Session2">Session2</option>
      </select>

      <div className="flex justify-between">
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
          onClick={handleSave}
        >
          Save
        </button>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default NewExamDialog;
