// AddSubjectDialog.js

import React, { useState } from 'react';

const AddSubjectDialog = ({ onSave, onCancel }) => {
  const [subject, setSubject] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [duration, setDuration] = useState('');
  const [marksMax, setMarksMax] = useState('');
  const [marksMin, setMarksMin] = useState('');

  const handleSave = () => {
    // Add validation or any additional logic if needed

    const subjectData = {
      subject,
      date,
      time,
      duration,
      marksMax,
      marksMin,
    };

    onSave(subjectData);
  };

  return (
    <div className="bg-white p-8 rounded-md">
      {/* AddSubjectDialog content */}
      <label htmlFor="subject" className="block text-gray-700 font-semibold mb-2">
        Subject:
      </label>
      <select
        id="subject"
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      >
        <option value="">Select Subject</option>
        {/* Add your subject options here */}
        <option value="Subject1">Subject1</option>
        <option value="Subject2">Subject2</option>
      </select>

      <label htmlFor="date" className="block text-gray-700 font-semibold mb-2">
        Date:
      </label>
      <input
        type="date"
        id="date"
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
        value={date}
        onChange={(e) => setDate(e.target.value)}
      />

      <label htmlFor="time" className="block text-gray-700 font-semibold mb-2">
        Time:
      </label>
      <input
        type="time"
        id="time"
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
        value={time}
        onChange={(e) => setTime(e.target.value)}
      />

      <label htmlFor="duration" className="block text-gray-700 font-semibold mb-2">
        Duration (minutes):
      </label>
      <input
        type="number"
        id="duration"
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
        value={duration}
        onChange={(e) => setDuration(e.target.value)}
      />

      <label htmlFor="marksMax" className="block text-gray-700 font-semibold mb-2">
        Max Marks:
      </label>
      <input
        type="number"
        id="marksMax"
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
        value={marksMax}
        onChange={(e) => setMarksMax(e.target.value)}
      />

      <label htmlFor="marksMin" className="block text-gray-700 font-semibold mb-2">
        Min Marks:
      </label>
      <input
        type="number"
        id="marksMin"
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
        value={marksMin}
        onChange={(e) => setMarksMin(e.target.value)}
      />

      <div className="flex justify-between">
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
          onClick={handleSave}
        >
          Save
        </button>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddSubjectDialog;
