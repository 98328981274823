import React, { useState } from 'react';

const Products = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddProductDialog, setShowAddProductDialog] = useState(false);
  const [products, setProducts] = useState([
    {
        id: '1',
        name: 'Product A',
        dimension: '10x20',
        weight: '5kg',
        color: 'Red',
        photo: 'https://picsum.photos/50/50?random=1',
      },
      {
        id: '2',
        name: 'Product B',
        dimension: '15x25',
        weight: '8kg',
        color: 'Blue',
        photo: 'https://picsum.photos/50/50?random=2',
      },
      {
        id: '3',
        name: 'Product C',
        dimension: '12x18',
        weight: '6kg',
        color: 'Green',
        photo: 'https://picsum.photos/50/50?random=3',
      },
  ]);
  const [newProduct, setNewProduct] = useState({
    id: '',
    name: '',
    dimension: '',
    weight: '',
    color: '',
    photo: '',
  });
  const [editingProduct, setEditingProduct] = useState(null);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAddProduct = () => {
    setEditingProduct(null);
    setShowAddProductDialog(true);
  };

  const handleEditProduct = (product) => {
    setEditingProduct(product);
    setShowAddProductDialog(true);
  };

  const handleFormChange = (event, field) => {
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [field]: event.target.value,
    }));
  };

  const handleSaveProduct = (product) => {
    setProducts((prevProducts) => [...prevProducts, product]);
    console.log('New Product:', product);
    setShowAddProductDialog(false);
    setNewProduct({
      id: '',
      name: '',
      dimension: '',
      weight: '',
      color: '',
      photo: '',
    });
  };

  const handleEditSaveProduct = (editedProduct) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) => (product.id === editedProduct.id ? editedProduct : product))
    );
    console.log('Edited Product:', editedProduct);
    setShowAddProductDialog(false);
    setEditingProduct(null);
  };

  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.dimension.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.weight.includes(searchTerm) ||
      product.color.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto mt-8 p-4">
      <h1 className="text-3xl font-bold mb-4">Product Management</h1>

      {/* Global Search Input */}
      <input
        type="text"
        placeholder="Search products..."
        value={searchTerm}
        onChange={handleSearch}
        className="mb-4 p-2 border border-gray-300 rounded-md w-full"
      />

      {/* Add New Product Button */}
      <button onClick={handleAddProduct} className="mb-4 bg-blue-500 text-white p-2 rounded-md">
        Add New Product
      </button>

      {/* Product Table */}
      <table className="w-full border border-gray-300">
  <thead>
    <tr>
      <th className="border border-gray-300 p-2">ID</th>
      <th className="border border-gray-300 p-2">Name</th>
      <th className="border border-gray-300 p-2">Dimension</th>
      <th className="border border-gray-300 p-2">Weight</th>
      <th className="border border-gray-300 p-2">Color</th>
      <th className="border border-gray-300 p-2">Photo</th>
      <th className="border border-gray-300 p-2">Actions</th>
    </tr>
  </thead>
  <tbody>
    {filteredProducts.map((product) => (
      <tr key={product.id} className="border border-gray-300">
        <td className="border border-gray-300 p-2">{product.id}</td>
        <td className="border border-gray-300 p-2">{product.name}</td>
        <td className="border border-gray-300 p-2">{product.dimension}</td>
        <td className="border border-gray-300 p-2">{product.weight}</td>
        <td className="border border-gray-300 p-2">{product.color}</td>
        <td className="border border-gray-300 p-2">
          <img src={product.photo} alt={`Product ${product.id}`} className="w-8 h-8" />
        </td>
        <td className="border border-gray-300 p-2">
          <button
            onClick={() => handleEditProduct(product)}
            className="bg-yellow-500 text-white p-2 rounded-md"
          >
            Edit
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>
      {/* Add/Edit Product Dialog */}
      {showAddProductDialog && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-md">
            <h2 className="text-xl font-bold mb-4">
              {editingProduct ? 'Edit Product' : 'Add New Product'}
            </h2>
            <form>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">ID:</label>
                <input
                  type="text"
                  value={editingProduct ? editingProduct.id : newProduct.id}
                  onChange={(e) => handleFormChange(e, 'id')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Name:</label>
                <input
                  type="text"
                  value={editingProduct ? editingProduct.name : newProduct.name}
                  onChange={(e) => handleFormChange(e, 'name')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Dimension:</label>
                <input
                  type="text"
                  value={editingProduct ? editingProduct.dimension : newProduct.dimension}
                  onChange={(e) => handleFormChange(e, 'dimension')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Weight:</label>
                <input
                  type="text"
                  value={editingProduct ? editingProduct.weight : newProduct.weight}
                  onChange={(e) => handleFormChange(e, 'weight')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Color:</label>
                <input
                  type="text"
                  value={editingProduct ? editingProduct.color : newProduct.color}
                  onChange={(e) => handleFormChange(e, 'color')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Photo:</label>
                <input
                  type="text"
                  value={editingProduct ? editingProduct.photo : newProduct.photo}
                  onChange={(e) => handleFormChange(e, 'photo')}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
            </form>
            <button
              onClick={() => {
                editingProduct ? handleEditSaveProduct(editingProduct) : handleSaveProduct(newProduct);
              }}
              className="bg-blue-500 text-white p-2 rounded-md mr-2"
            >
              Save
            </button>
            <button
              onClick={() => {
                setEditingProduct(null);
                setShowAddProductDialog(false);
              }}
              className="bg-gray-500 text-white p-2 rounded-md"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
