// src/components/AddSubject.js
import React, { useState } from 'react';

const AddSubject = ({ onAddSubject }) => {
  const [subjectName, setSubjectName] = useState('');
  const [subjectCode, setSubjectCode] = useState('');

  const handleSubmit = () => {
    // Validate and submit subject data
    if (subjectName.trim() === '' || subjectCode.trim() === '') {
      alert('Please fill in all fields');
      return;
    }

    const newSubject = {
      id: Math.floor(Math.random() * 1000), // Generate a random ID (replace with server-generated ID in real application)
      subjectName,
      subjectCode,
    };

    // Pass the new subject data to the parent component
    onAddSubject(newSubject);

    // Reset form fields
    setSubjectName('');
    setSubjectCode('');
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Add New Subject</h2>
      <div className="mb-4">
        <label htmlFor="subjectName" className="block text-sm font-medium text-gray-600">
          Subject Name
        </label>
        <input
          type="text"
          id="subjectName"
          value={subjectName}
          onChange={(e) => setSubjectName(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="subjectCode" className="block text-sm font-medium text-gray-600">
          Subject Code
        </label>
        <input
          type="text"
          id="subjectCode"
          value={subjectCode}
          onChange={(e) => setSubjectCode(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>
      <button onClick={handleSubmit} className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
        Add Subject
      </button>
    </div>
  );
};

export default AddSubject;
