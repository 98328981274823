import React, { useState } from "react";

function ViewTimetable() {
  const [selectedClass, setSelectedClass] = useState(null);

  const dummyData = [
    {
      id: 1,
      clsName: "First-A",
      details: [
        {
          day: "Mon",
          startTime: "08:30 AM",
          endTime: "09:30 AM",
          subject: "English",
          teacher: "Shyam Singh",
        },
        {
          day: "Mon",
          startTime: "09:30 AM",
          endTime: "10:30 AM",
          subject: "Hindi",
          teacher: "Shubham Singh",
        },
        {
          day: "Tue",
          startTime: "10:00 AM",
          endTime: "11:00 AM",
          subject: "Math",
          teacher: "Rajesh Kumar",
        },
        {
          day: "Tue",
          startTime: "11:00 AM",
          endTime: "12:00 PM",
          subject: "Science",
          teacher: "Suman Verma",
        },
        {
          day: "Wed",
          startTime: "11:00 AM",
          endTime: "12:00 PM",
          subject: "Science",
          teacher: "Suman Verma",
        },{
          day: "Thu",
          startTime: "11:00 AM",
          endTime: "12:00 PM",
          subject: "Science",
          teacher: "Suman Verma",
        },{
          day: "Fri",
          startTime: "11:00 AM",
          endTime: "12:00 PM",
          subject: "Science",
          teacher: "Suman Verma",
        },
        {
          day: "Sat",
          startTime: "11:00 AM",
          endTime: "12:00 PM",
          subject: "Science",
          teacher: "Suman Verma",
        },
        // Add more entries if needed
      ],
    },
    {
      id: 2,
      clsName: "First-B",
      details: [
        {
          day: "Mon",
          startTime: "08:30 AM",
          endTime: "09:30 AM",
          subject: "History",
          teacher: "Shyam Singh",
        },
        {
          day: "Mon",
          startTime: "09:30 AM",
          endTime: "10:30 AM",
          subject: "Hindi",
          teacher: "Shubham Singh",
        },
        {
          day: "Tue",
          startTime: "10:00 AM",
          endTime: "11:00 AM",
          subject: "Math",
          teacher: "Rajesh Kumar",
        },
        {
          day: "Tue",
          startTime: "11:00 AM",
          endTime: "12:00 PM",
          subject: "Science",
          teacher: "Suman Verma",
        },
        {
          day: "Wed",
          startTime: "11:00 AM",
          endTime: "12:00 PM",
          subject: "Science",
          teacher: "Suman Verma",
        },{
          day: "Thu",
          startTime: "11:00 AM",
          endTime: "12:00 PM",
          subject: "Science",
          teacher: "Suman Verma",
        },{
          day: "Fri",
          startTime: "11:00 AM",
          endTime: "12:00 PM",
          subject: "Science",
          teacher: "Suman Verma",
        },
        {
          day: "Sat",
          startTime: "11:00 AM",
          endTime: "12:00 PM",
          subject: "Science",
          teacher: "Suman Verma",
        },
        // Add more entries if needed
      ],
    }
    // Add more classes if needed
  ];

  const handleClassChange = (event) => {
    const selectedClassName = event.target.value;
    const selectedClass = dummyData.find((cls) => cls.clsName === selectedClassName);
    setSelectedClass(selectedClass);
  };

  const getWeekdays = (data) => {
    const weekdays = Array.from(new Set(data.map((entry) => entry.day)));
    return weekdays.sort(); // Ensure weekdays are sorted in order (e.g., Mon, Tue, Wed, ...)
  };

  const getTimeSlots = (data) => {
    const timeSlots = Array.from(new Set(data.map((entry) => `${entry.startTime} - ${entry.endTime}`)));
    return timeSlots.sort(); // Ensure time slots are sorted
  };

  const getEntriesByDayAndTime = (data) => {
    return data.reduce((entriesByDayAndTime, entry) => {
      if (!entriesByDayAndTime[entry.day]) {
        entriesByDayAndTime[entry.day] = {};
      }
      entriesByDayAndTime[entry.day][`${entry.startTime} - ${entry.endTime}`] = entry;
      return entriesByDayAndTime;
    }, {});
  };

  return (
    <div className="container mx-auto md:mt-8">
            <h1 className="text-2xl md:font-bold md:mb-3 mt-1 font-bold md:text-left text-center">Time Table</h1>
      <label className="text-lg font-semibold mb-2 block">Select Class:</label>
      <select
        className="border p-2 mb-4 w-full"
        onChange={handleClassChange}
        value={selectedClass ? selectedClass.clsName : ""}
      >
        <option value="" disabled>Select Class</option>
        {dummyData.map((cls) => (
          <option key={cls.id} value={cls.clsName}>
            {cls.clsName}
          </option>
        ))}
      </select>

      {selectedClass && (
        <div className="overflow-auto">
          <h2 className="text-2xl font-bold mb-4 text-center">{selectedClass.clsName}</h2>

          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300 p-2"></th>
                {getWeekdays(selectedClass.details).map((day) => (
                  <th key={day} className="border border-gray-300 p-2 bg-rose-400">
                    {day}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {getTimeSlots(selectedClass.details).map((timeSlot) => (
                <tr key={timeSlot}>
                  <td className="border border-gray-300 p-2 w-1/6">{timeSlot}</td>
                  {getWeekdays(selectedClass.details).map((day) => (
                    <td
                      key={day}
                      className="border border-gray-300 p-2 bg-gray-50"
                    >
                      {getEntriesByDayAndTime(selectedClass.details)[day]?.[timeSlot]?.subject || ""}
                      <br />
                      {getEntriesByDayAndTime(selectedClass.details)[day]?.[timeSlot]?.teacher || ""}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default ViewTimetable;
