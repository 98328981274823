// src/components/PayFee.js
import React, { useState } from "react";

const FeePaymentEntry = () => {
  // Dummy data
  const currentMonth = "January";
  const classes = ["Class 1A", "Class 1B", "Class 2A", "Class 2B"]; // Add more classes as needed
  const students = [
    {
      id: 1,
      name: "John Doe",
      fatherName: "Mike Doe",
      motherName: "Jane Doe",
      rollNo: "101",
      class: "Class 1A",
      photo:
        "https://media.istockphoto.com/id/1353379172/photo/cute-little-african-american-girl-looking-at-camera.jpg?s=2048x2048&w=is&k=20&c=KI0p6tdb1rV8Uj-A9095dhtlD6RYSQcILmbCJgfPMMU=",
    },
    {
      id: 2,
      name: "Jane Smith",
      fatherName: "Bob Smith",
      motherName: "Alice Smith",
      rollNo: "102",
      class: "Class 1A",
      photo:
        "https://media.istockphoto.com/id/939108006/photo/portrait-of-cute-girl.jpg?s=1024x1024&w=is&k=20&c=Dx2AVGCIdJK6V3vNb4AB3uUXBTobMp0ikrd0hhgp_oE=",
    },
    {
      id: 1,
      name: "John Doe",
      fatherName: "Mike Doe",
      motherName: "Jane Doe",
      rollNo: "101",
      class: "Class 1A",
      photo:
        "https://media.istockphoto.com/id/1353379172/photo/cute-little-african-american-girl-looking-at-camera.jpg?s=2048x2048&w=is&k=20&c=KI0p6tdb1rV8Uj-A9095dhtlD6RYSQcILmbCJgfPMMU=",
    },
    {
      id: 1,
      name: "John Doe",
      fatherName: "Mike Doe",
      motherName: "Jane Doe",
      rollNo: "101",
      class: "Class 1A",
      photo:
        "https://media.istockphoto.com/id/1353379172/photo/cute-little-african-american-girl-looking-at-camera.jpg?s=2048x2048&w=is&k=20&c=KI0p6tdb1rV8Uj-A9095dhtlD6RYSQcILmbCJgfPMMU=",
    },
    {
      id: 1,
      name: "John Doe",
      fatherName: "Mike Doe",
      motherName: "Jane Doe",
      rollNo: "101",
      class: "Class 1A",
      photo:
        "https://media.istockphoto.com/id/1353379172/photo/cute-little-african-american-girl-looking-at-camera.jpg?s=2048x2048&w=is&k=20&c=KI0p6tdb1rV8Uj-A9095dhtlD6RYSQcILmbCJgfPMMU=",
    },
    {
      id: 1,
      name: "John Doe",
      fatherName: "Mike Doe",
      motherName: "Jane Doe",
      rollNo: "101",
      class: "Class 1A",
      photo:
        "https://media.istockphoto.com/id/1353379172/photo/cute-little-african-american-girl-looking-at-camera.jpg?s=2048x2048&w=is&k=20&c=KI0p6tdb1rV8Uj-A9095dhtlD6RYSQcILmbCJgfPMMU=",
    },
    {
      id: 1,
      name: "John Doe",
      fatherName: "Mike Doe",
      motherName: "Jane Doe",
      rollNo: "101",
      class: "Class 1A",
      photo:
        "https://media.istockphoto.com/id/1353379172/photo/cute-little-african-american-girl-looking-at-camera.jpg?s=2048x2048&w=is&k=20&c=KI0p6tdb1rV8Uj-A9095dhtlD6RYSQcILmbCJgfPMMU=",
    },
    {
      id: 1,
      name: "John Doe",
      fatherName: "Mike Doe",
      motherName: "Jane Doe",
      rollNo: "101",
      class: "Class 1A",
      photo:
        "https://media.istockphoto.com/id/1353379172/photo/cute-little-african-american-girl-looking-at-camera.jpg?s=2048x2048&w=is&k=20&c=KI0p6tdb1rV8Uj-A9095dhtlD6RYSQcILmbCJgfPMMU=",
    },
    {
      id: 1,
      name: "John Doe",
      fatherName: "Mike Doe",
      motherName: "Jane Doe",
      rollNo: "101",
      class: "Class 1A",
      photo:
        "https://media.istockphoto.com/id/1353379172/photo/cute-little-african-american-girl-looking-at-camera.jpg?s=2048x2048&w=is&k=20&c=KI0p6tdb1rV8Uj-A9095dhtlD6RYSQcILmbCJgfPMMU=",
    },
    // Add more student data as needed
  ];

  // Dummy fee details for each month
  const feeDetails = [
    { month: "January", totalFee: 10000, remaining: 10000 },
    { month: "February", totalFee: 10000, remaining: 8000 },
    // Add more months as needed
  ];

  const [selectedClass, setSelectedClass] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);

  const handlePayFee = (student) => {
    // Implement logic to handle fee payment
    console.log("Paying fee for student:", student);
    // Reset selected student after payment
    setSelectedStudent(null);
  };

  const filteredStudents = students.filter(
    (student) =>
      (selectedClass === "" || student.class === selectedClass) &&
      (searchQuery === "" ||
        student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.rollNo.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className="md:max-w-full max-w-3xl mx-auto p-4 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Pay Fee</h2>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name or roll number"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>
      <div className="mb-4">
        <select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        >
          <option value="">Select Class</option>
          {classes.map((cls) => (
            <option key={cls} value={cls}>
              {cls}
            </option>
          ))}
        </select>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Photo
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Father Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Mother Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Roll No
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Class
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student) => (
            <tr key={student.id}>
              <td className="px-6 py-4">
                <img
                  src={student.photo}
                  alt={student.name}
                  className="w-16 h-16 rounded-full"
                />
              </td>
              <td className="px-6 py-4">{student.name}</td>
              <td className="px-6 py-4">{student.fatherName}</td>
              <td className="px-6 py-4">{student.motherName}</td>
              <td className="px-6 py-4">{student.rollNo}</td>
              <td className="px-6 py-4">{student.class}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  onClick={() => setSelectedStudent(student)}
                  className="text-blue-500 hover:underline focus:outline-none"
                >
                  Pay Fee
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedStudent && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    {/* Student photo or placeholder */}
                    <img
                      src={selectedStudent.photo}
                      alt={selectedStudent.name}
                      className="w-12 h-12 rounded-full"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      {selectedStudent.name}
                    </h3>
                    <p className="text-sm text-gray-500 mb-4">
                      Father: {selectedStudent.fatherName}, Mother:{" "}
                      {selectedStudent.motherName}
                    </p>
                    <p className="text-sm text-gray-500 mb-4">
                      Roll No: {selectedStudent.rollNo}, Class:{" "}
                      {selectedStudent.class}
                    </p>
                    {/* Table for fee details */}

                    {/* Table for fee details */}

                    <table className="min-w-full divide-y divide-gray-200 mb-4">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Month
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Total Fee
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Remaining Amount
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Enter Amount to Pay
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {feeDetails.map((feeDetail) => (
                          <tr key={feeDetail.month}>
                            <td className="px-6 py-4">{feeDetail.month}</td>
                            <td className="px-6 py-4">{feeDetail.totalFee}</td>
                            <td className="px-6 py-4">{feeDetail.remaining}</td>
                            <td className="px-6 py-4">
                              <input
                                type="text"
                                defaultValue={
                                  feeDetail.remaining === 0 ? "0" : ""
                                }
                                className="p-2 border rounded w-full"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={() => handlePayFee(selectedStudent)}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Pay Fee
                </button>
                <button
                  onClick={() => setSelectedStudent(null)}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeePaymentEntry;
