// src/ViewNoticePage.js
import React, { useState } from 'react';

const ViewNoticePage = () => {
  const [notices, setNotices] = useState([
    { id: 1, title: 'Notice 1', content: 'This is the content of Notice 1' },
    { id: 2, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 3, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 4, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 5, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 6, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 7, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 8, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 9, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 10, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 11, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 12, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 13, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 14, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 15, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 16, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 17, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 18, title: 'Notice 2', content: 'This is the content of Notice 2' },
    { id: 19, title: 'Notice 2', content: 'This is the content of Notice 2' },
    // ... add more notices as needed
  ]);

  return (
    <>
      <h1 className="text-3xl font-semibold m-4">View Notices</h1>
    <div className="container mx-auto my-8 bg-white">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {notices.map((notice) => (
          <div key={notice.id} className="bg-gradient-to-r from-purple-500 m-4 to-blue-500 p-6 rounded-lg shadow-md text-white">
            <h2 className="text-xl font-semibold mb-2">{notice.title}</h2>
            <p className="text-gray-600">{notice.content}</p>
          </div>
        ))}
      </div>
    </div>
    </>
    
  );
};

export default ViewNoticePage;
