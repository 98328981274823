// src/components/ViewFeeGroups.js
import React, { useState } from 'react';

const ViewFeeGroups = () => {
  const [feeGroups, setFeeGroups] = useState([
    { id: 1, feeGroupName: 'Standard', amountPerMonth: '1000' },
    { id: 2, feeGroupName: 'Premium', amountPerMonth: '2000' },
    // Add more dummy data as needed
  ]);

  const [editFeeGroup, setEditFeeGroup] = useState(null);

  const handleEditClick = (feeGroup) => {
    setEditFeeGroup(feeGroup);
  };

  const handleSaveEdit = () => {
    // Implement logic to save edited fee group data
    console.log('Saving edited fee group data:', editFeeGroup);
    setEditFeeGroup(null);
  };

  const handleCancelEdit = () => {
    setEditFeeGroup(null);
  };

  return (
    <div className="max-w-2xl mx-auto p-4 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">View Fee Groups</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fee Group Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount Per Month</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody>
          {feeGroups.map((feeGroup) => (
            <tr key={feeGroup.id}>
              <td className="px-6 py-4">{feeGroup.feeGroupName}</td>
              <td className="px-6 py-4">{feeGroup.amountPerMonth}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button onClick={() => handleEditClick(feeGroup)} className="text-blue-500 hover:underline focus:outline-none">
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editFeeGroup && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    {/* Edit icon or placeholder */}
                    Edit
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                      Edit Fee Group Information
                    </h3>
                    {/* Form for editing fee group information */}
                    <form>
                      <div className="mb-4">
                        <label htmlFor="editFeeGroupName" className="block text-sm font-medium text-gray-600">
                          Fee Group Name
                        </label>
                        <input
                          type="text"
                          id="editFeeGroupName"
                          name="editFeeGroupName"
                          value={editFeeGroup.feeGroupName}
                          onChange={(e) => setEditFeeGroup({ ...editFeeGroup, feeGroupName: e.target.value })}
                          className="mt-1 p-2 border rounded w-full"
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="editAmountPerMonth" className="block text-sm font-medium text-gray-600">
                          Amount Per Month
                        </label>
                        <input
                          type="text"
                          id="editAmountPerMonth"
                          name="editAmountPerMonth"
                          value={editFeeGroup.amountPerMonth}
                          onChange={(e) => setEditFeeGroup({ ...editFeeGroup, amountPerMonth: e.target.value })}
                          className="mt-1 p-2 border rounded w-full"
                        />
                      </div>
                      {/* Add more fields as needed */}
                    </form>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleSaveEdit}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Save
                </button>
                <button
                  onClick={handleCancelEdit}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewFeeGroups;
