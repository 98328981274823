import React from 'react';

const TeamMember = ({ name, role, photo }) => {
  return (
    <div className="max-w-xs mx-2 bg-white shadow-lg rounded-lg overflow-hidden transition duration-300 transform hover:scale-105">
      <img className="w-full h-56 object-cover object-center rounded-t-lg" src={photo} alt={name} />
      <div className="py-4 px-6 bg-gray-800 text-white rounded-b-lg">
        <h2 className="text-xl font-semibold">{name}</h2>
        <p className="text-sm">{role}</p>
      </div>
    </div>
  );
};

export default TeamMember;
