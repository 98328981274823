import React from "react";

const ProfileView = ({ profile }) => {
  return (
    <>
    <h1 className="text-3xl font-bold mb-6 text-center">Profile</h1>
    
    <div className="container mx-auto mt-8 p-4 bg-white rounded shadow-md max-w-md">
      
      <div className="flex items-center">
        <img
          className="h-24 w-24 rounded-full mr-4 border-4 border-blue-500"
          src={profile.avatar}
          alt="Profile Avatar"
        />
        <div>
          <p className="text-2xl font-bold">{profile.name}</p>
          <p className="text-gray-700">{profile.email}</p>
          {/* Add more profile information here */}
        </div>
      </div>
      <div className="flex flex-col mt-4 p-4 bg-gray-100 rounded">
        <p className="text-gray-700 mb-2"><span className="font-bold">Father:</span> {profile.fatherName}</p>
        <p className="text-gray-700 mb-2"><span className="font-bold">Mother:</span> {profile.motherName}</p>
        {/* Add more profile information here */}
      </div>
    </div>
    </>
  );
};

export default ProfileView;
