import React from 'react';
import TeamMember from './TeamMember';

const teamData = [
  {
    id: 1,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },
  {
    id: 2,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 3,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 4,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 5,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 6,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 7,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 10,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 11,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },
  {
    id: 12,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 13,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 14,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 15,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 16,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 17,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  },{
    id: 18,
    name: 'John Doe',
    role: 'Developer',
    photo: 'https://placekitten.com/300/300', // replace with actual photo URL
  }
  // Add more team members as needed
];

const TeamPage = () => {
  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-3xl font-bold mb-6 text-center text-white">Our Team</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {teamData.map((member) => (
          <TeamMember key={member.id} {...member} />
        ))}
      </div>
    </div>
  );
};

export default TeamPage;
